interface ProgressBarProps {
  progress: number;
  type: "primary" | "secondary";
}

const ProgressBar = ({ progress, type }: ProgressBarProps) => {
  const cappedProgress = Math.min(progress, 100);
  return (
    <div className="flex gap-3 items-center">
      <div className="w-full overflow-hidden h-2 text-xs flex rounded bg-border_secondary">
        <div
          style={{ width: `${cappedProgress}%` }}
          className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center ${
            type === "primary" ? "bg-primary_color" : "bg-gray_700"
          }`}
        ></div>
      </div>
      <span className="text-sm text-text_tertiary_color font-medium">{cappedProgress}%</span>
    </div>
  );
};

export default ProgressBar;
