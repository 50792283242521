import { useEffect, useState } from "react";

import { cn } from "../../utils/classNames";
import Check from "@untitled-ui/icons-react/build/esm/Check";
import HelpCircle from "@untitled-ui/icons-react/build/esm/HelpCircle"
import Tooltip from "../ui/Tooltip";

type Props = {
    wrapperClassNames?: string
    buttonClassNames?: string
    isChecked: boolean
    onChange: (isChecked: boolean) => void
    children?: React.ReactNode
    helpDescription?: string
}

const PillToggle = ({ isChecked, onChange, wrapperClassNames, buttonClassNames, children, helpDescription }: Props) => {

    const [checked, setChecked] = useState(isChecked)

    useEffect(() => {
        if (isChecked !== undefined && isChecked !== checked) setChecked(isChecked)
    }, [isChecked])

    const handleChecked = () => {
        setChecked(prev => !prev)
        onChange(!isChecked)
    }

    return (
        <button
            type="button"
            className={cn(
                "font-medium text-sm border-border_primary text-text_tertiary_color border w-fit py-1.5 px-3 text-left flex gap-2 items-center rounded-full",
                checked && "border-primary_color  bg-background_primary",
                buttonClassNames
            )}
            onClick={handleChecked}
        >
            {children}
            {/* {checked ? <Check className=" w-3.5 h-3.5 text-primary_color" /> : null} */}
            {helpDescription ? <Tooltip content={helpDescription} disableCheckOverflow >
                <HelpCircle className=" w-5 h-5 sm:w-6 sm:h-6 cursor-pointer text-dark_gray" />
            </Tooltip> : null}
        </button>
    )
}

export default PillToggle