import { cn } from "../../utils/classNames"

type Props = {
    orientation?: "vertical" | "horizontal"
    className?: string
}

const Separator = ({orientation = "horizontal", className}: Props) => {
  return <div className={cn("bg-border_primary",orientation === "horizontal" ? "h-[1px] w-full" : "h-full w-[1px]", className)}/>
}

export default Separator