import React from "react";
import GetQuoteForm from "./form/GetQuoteForm";
import { classNames } from "../../utils/classNames";
import GetBulkQuoteForm from "./form/GetBulkQuoteForm";
import { WidgetSettingsJson } from "../../models/WidgetSettingsJson";
import { Widget } from "../../models/Widget";
import { Carrier } from "../../models/Carrier";

export interface QuoteWrapperProps {
    data: Widget & {carrier: Carrier}
}
const QuoteWrapper: React.FC<QuoteWrapperProps> = (props:QuoteWrapperProps) => {
    const [isFreightQuote, setIsFreightQuote] = React.useState(true);
    return (
        <div className=" bg-background_light w-full">
            <div className="grid grid-cols-2">
                <div
                    onClick={() => setIsFreightQuote(true)}
                    className={classNames(
                        "cursor-pointer text-base py-4 flex flex-col justify-center items-center",
                        isFreightQuote ? "" : "bg-primary_color text-white"
                    )}
                >
                    <p className="  text-base sm:text-lg sm font-semibold">Freight Quote</p>
                    <p className=" text-xs font-normal">Book today & save.</p>
                </div>
                <div
                    onClick={() => setIsFreightQuote(false)}
                    className={classNames(
                        "cursor-pointer text-base py-4 flex flex-col justify-center items-center",
                        !isFreightQuote ? "" : "bg-primary_color text-white"
                    )}
                >
                    <p className=" text-base sm:text-lg font-semibold">Freight Assessment</p>
                    <p className=" text-xs font-normal">Save by shipping with us.</p>
                </div>
            </div>
            <div className="p-3 md:p-5 w-full">
                {isFreightQuote && <GetQuoteForm data={props.data}/>}
                {!isFreightQuote && <GetBulkQuoteForm data={props.data} />}
            </div>
        </div>
    )
};

export default QuoteWrapper;
