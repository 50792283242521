import XClose from "@untitled-ui/icons-react/build/esm/XClose"
import PopupWrapper from "./PopupWrapper"
import Button from "../button/Button"
import { useSelector } from "react-redux"
import { getIsOpen, getOnClose } from "../../store/features/ErrorPopupSlice"

const ErrorPopup = () => {

    const isOpen = useSelector(getIsOpen)
    const onClose = useSelector(getOnClose)

    return (
        <PopupWrapper
            isOpen={isOpen}
            onClose={onClose}
        >

            <XClose className=" mx-auto my-8 size-20 bg-secondary_light_color p-2 text-primary_error rounded-full" />
            <h1 className=" mt-4 text-lg font-semibold text-center text-text_primary_color">Something went wrong!</h1>
            <p className=" text-base text-center font-normal text-text_secondary_color">Please try again.</p>
            <div className="mt-8">
                <Button onClick={onClose} type="button" size="xl" label="Please try again"/>
            </div>
        </PopupWrapper>
    )
}

export default ErrorPopup
