import React, { forwardRef } from "react";
import { classNames, cn } from "../../../utils/classNames";
import InputLabelWrapper, { HelpPopupProps } from "./InputLabelWrapper";
import InputBottomMessage from "./InputBottomMessage";
import ToggleButton from "../ToggleButton";

interface InputWrapperProps {
  children: React.ReactNode;
  label?: string;
  subtitle?: string;
  error?: string;
  showLabel?: boolean;
  showErrorIcon?: boolean;
  showToggleButton?: boolean;
  showErrorMessage?: boolean;
  toggleDisable?: (value: 0 | 1) => void;
  showHelpData?: HelpPopupProps 
}

const InputWrapper: React.ForwardRefRenderFunction<HTMLDivElement, InputWrapperProps> = (
  {
    children,
    label,
    subtitle,
    error,
    showLabel = true,
    showErrorIcon = false,
    showToggleButton = false,
    toggleDisable,
    showErrorMessage = true,
    showHelpData,
  }: InputWrapperProps,
  ref
) => {
  return (
    <div className="relative" ref={ref}>
      <InputLabelWrapper
        label={label}
        showLabel={showLabel}
        showToggleButton={showToggleButton}
        toggleDisable={toggleDisable}
        showHelpData={showHelpData}
      />
      <div className={cn("relative mt-2" ,!showLabel && "mt-0")}>{children}</div>
      {/* {showErrorMessa.ge && <InputBottomMessage showLabel={showLabel} error={error} subtitle={subtitle} showErrorIcon={showErrorIcon} />} */}
    </div>
  );
};

export default forwardRef(InputWrapper);
