import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { QueryParams } from "../models/QueryParams";

export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = localStorage.getItem("token");
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export const setQueryParam = (url: string, queryParam: QueryParams) => {
  let { page = 1, search, status } = queryParam;
  url += `?page=${page}`;
  if (search) {
    url += `&q=${search}`;
  }
  if (status !== undefined) {
    url += `&status=${status.join(',')}`;
  }
  return url;
};

export const API = {
  baseQuery,
  setQueryParam,
};
