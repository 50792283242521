import React, { useEffect } from "react";
import { useState } from "react";

interface ToggleButtonProps {
  handleChange: (value: boolean) => void;
  label : string;
  checked: boolean;
}

const ToggleButton = ({ handleChange, label, checked }: ToggleButtonProps) => {
  const [isChecked, setIsChecked] = React.useState<boolean>(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const setChecked = (value: boolean) => {
    setIsChecked(value);
    handleChange(value);
  };

  return (
    <label className="inline-flex items-center cursor-pointer gap-2">
      <input
        type="checkbox"
        value={isChecked ? 1 : 0}
        className="sr-only peer"
        title="toggle button"
        onChange={(e) => {
          setChecked(e.target.checked);
        }}
      />
      <div className="relative w-10 h-6 md:w-14 md:h-8 bg-border_primary 
      peer-focus:outline-none rounded-full 
      peer peer-checked:after:translate-x-[85%] rtl:peer-checked:after:-translate-x-[85%] peer-checked:after:border-white 
      after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-100 after:rounded-full after:h-5 after:w-5 md:after:h-7 md:after:w-7 after:transition-all peer-checked:bg-primary_color"></div>
      <p className=" text-text_label text-xs min-[400px]:text-sm font-normal">{label}</p>
    </label>
  );
};

export default ToggleButton;
