import { capitalizeFirstLetter } from "../utils/CommonUtil";

export interface MasterPostCode {
    id?: number;
    postcode: string;
    identityPostcode: string;
    suburbName: string;
    areaCode: string;
    areaName: string;
    stateName: string;
    stateCode: string;
    cityCode: string;
    cityName: string;
    country: string;
    latGoogle: string;
    longGoogle: string;
    region: string;
    regionCode: string;
}

export const defaultPostCode: MasterPostCode = {
    postcode: "",
    identityPostcode: "",
    suburbName: "",
    areaCode: "",
    areaName: "",
    stateName: "",
    stateCode: "",
    cityCode: "",
    cityName: "",
    country: "",
    latGoogle: "",
    longGoogle: "",
    region: "",
    regionCode: ""
}


export const getDisplayValueByFieldType = (postCode: any, fieldName : keyof MasterPostCode) => {
    switch(fieldName) {
        case 'postcode' : return `${postCode.postcode}, ${postCode.cityName}, ${postCode.stateName}`
        case 'identityPostcode' : return `${capitalizeFirstLetter(postCode.suburbName)} (${postCode.postcode}), ${postCode.cityName}, ${postCode.stateCode}`
        case 'suburbName' : return `${capitalizeFirstLetter(postCode.suburbName)} (${postCode.postcode}), ${postCode.cityName}, ${postCode.stateCode}`
        case 'areaName' : return `${postCode.areaName}, ${postCode.stateCode}`
        case 'cityName' : return `${postCode.cityName}, ${postCode.stateCode}`
        case 'stateName' : return `${postCode.stateName} (${postCode.stateCode})`
        case 'region' : return `${postCode.region}, ${postCode.country}`
        default : return `${capitalizeFirstLetter(postCode.suburbName)} (${postCode.postcode}), ${postCode.cityName}, ${postCode.stateCode}`
    }
}