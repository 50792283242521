import React, { useEffect, useState } from "react";
import { classNames } from "../../utils/classNames";

import { parsePhoneNumberFromString, getCountryCallingCode } from "libphonenumber-js";

import InputWrapper from "./wrapper/InputWrapper";

import Dropdown from "./Dropdown";
import { PhoneNumOptionByCountry, PhoneNumberDirectoryOptions } from "../../enum/PhoneNumberDirectory";


interface phoneNumberProps {
    label?: string;
    handleChange: (value: string) => void;
    placeholder?: string;
    error?: string;
    value?: string;
    disabled?: boolean;
    subtitle?: string;
    carrierCountryCode?: string
}

const PhoneNumberInput = ({ handleChange,
    placeholder,
    label,
    error,
    value,
    disabled,
    subtitle, carrierCountryCode }: phoneNumberProps) => {
    const [countryCodeOption, setCountryCodeOption] = useState<any>(PhoneNumOptionByCountry(carrierCountryCode));
    const [countryCode, setCountryCode] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState<string>();

    useEffect(() => {
        if (value) {
            const phoneNumber = parsePhoneNumberFromString(value);
            if (phoneNumber) {
                setCountryCodeOption({ label: "+" + phoneNumber.countryCallingCode, value: phoneNumber.countryCallingCode });
                setCountryCode(phoneNumber.countryCallingCode);
                setPhoneNumber(phoneNumber.nationalNumber.toString());
            }
        } 
        if (value == "") {
            setPhoneNumber(value)
        }
    }, [value]);

    const onChange = (e: any) => {
        setPhoneNumber(e.target.value);
    };

    useEffect(() => {
        handleChange("+" + countryCodeOption.value + phoneNumber)
        // handleChange("+" + "61" + phoneNumber);
    }, [countryCode, phoneNumber, countryCodeOption.value]);

    return (
        <InputWrapper
            label={label}
            subtitle={subtitle}
            error={error}
        >
            <div className="flex gap-1">
                    <div className="w-[4.2rem] relative">
                        <Dropdown containerClassName="px-1" showLabel={false} value={countryCodeOption} options={PhoneNumberDirectoryOptions()}
                            handleChange={setCountryCodeOption} showErrorMessage={false} />
                    </div>
                    <input
                        disabled={disabled}
                        type="text"
                        min={0}
                        value={phoneNumber}
                        placeholder={placeholder}
                        onChange={onChange}
                        className={classNames(
                            error
                                ? "border-primary_error focus:border-primary_error focus:outline-primary_error"
                                : "border-border_primary focus:border-primary_color focus:outline-primary_color",
                            "border  rounded-lg px-3 focus:outline w-[calc(100%-4.2rem)]"
                        )}
                    />
            </div>
        </InputWrapper>
    )
}

export default PhoneNumberInput;