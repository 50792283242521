import { DropdownOption } from "../models/DropdownOption";
import { EnumProperties } from "../models/EnumProperties";


export enum ZoneCountry {
    AUSTRALIA = "AU",
    NEW_ZEALAND = "NZ",
    UNITED_STATES = "US",
    UNITED_KINGDOM = "UK",
    INDIA = "IN",
    IRELAND = "IE",
    GERMANY = "DE",
    FRANCE = "FR",
    ITALY = "IT",
    NETEHERLANDS = "NL",
    AFGHANISTAN = "AF",
    ALAND_ISLANDS = "AX",
    ALBANIA = "AL",
    ALGERIA = "DZ",
    AMERICAN_SAMOA = "AS",
    ANDORRA = "AD",
    ANGOLA = "AO",
    ANGUILLA = "AI",
    ANTARCTICA = "AQ",
    ANTIGUA_AND_BARBUDA = "AG",
    ARGENTINA = "AR",
    ARMENIA = "AM",
    ARUBA = "AW",
    AUSTRIA = "AT",
    AZERBAIJAN = "AZ",
    BAHAMAS = "BS",
    BAHRAIN = "BH",
    BANGLADESH = "BD",
    BARBADOS = "BB",
    BELARUS = "BY",
    BELGIUM = "BE",
    BELIZE = "BZ",
    BENIN = "BJ",
    BERMUDA = "BM",
    BHUTAN = "BT",
    BOLIVIA = "BO",
    BONAIRE = "BQ",
    BOSNIA_AND_HERZEGOVINA = "BA",
    BOTSWANA = "BW",
    BOUVET_ISLAND = "BV",
    BRAZIL = "BR",
    BRITISH_INDIAN_OCEAN_TERRITORY = "IO",
    BRUNEI_DARUSSALAM = "BN",
    BULGARIA = "BG",
    BURKINA_FASO = "BF",
    BURUNDI = "BI",
    CABO_VERDE = "CV",
    CAMBODIA = "KH",
    CAMEROON = "CM",
    CANADA = "CA",
    CAYMAN_ISLANDS = "KY",
    CENTRAL_AFRICAN_REPUBLIC = "CF",
    CHAD = "TD",
    CHILE = "CL",
    CHINA = "CN",
    CHRISTMAS_ISLAND = "CX",
    COCOS_ISLANDS = "CC",
    COLOMBIA = "CO",
    COMOROS = "KM",
    CONGO = "CG",
    CONGO_DEMOCRATIC_REPUBLIC = "CD",
    COOK_ISLANDS = "CK",
    COSTA_RICA = "CR",
    COTE_DIVOIRE = "CI",
    CROATIA = "HR",
    CUBA = "CU",
    CURACAO = "CW",
    CYPRUS = "CY",
    CZECHIA = "CZ",
    DENMARK = "DK",
    DJIBOUTI = "DJ",
    DOMINICA = "DM",
    DOMINICAN_REPUBLIC = "DO",
    ECUADOR = "EC",
    EGYPT = "EG",
    EL_SALVADOR = "SV",
    EQUATORIAL_GUINEA = "GQ",
    ERITREA = "ER",
    ESTONIA = "EE",
    ESWATINI = "SZ",
    ETHIOPIA = "ET",
    FALKLAND_ISLANDS = "FK",
    FAROE_ISLANDS = "FO",
    FIJI = "FJ",
    FINLAND = "FI",
    FRENCH_GUIANA = "GF",
    FRENCH_POLYNESIA = "PF",
    FRENCH_SOUTHERN_TERRITORIES = "TF",
    GABON = "GA",
    GAMBIA = "GM",
    GEORGIA = "GE",
    GHANA = "GH",
    GIBRALTAR = "GI",
    GREECE = "GR",
    GREENLAND = "GL",
    GRENADA = "GD",
    GUADELOUPE = "GP",
    GUAM = "GU",
    GUATEMALA = "GT",
    GUERNSEY = "GG",
    GUINEA = "GN",
    GUINEA_BISSAU = "GW",
    HAITI = "HT",
    HEARD_ISLAND = "HM",
    HOLY_SEE = "VA",
    HONDURAS = "HN",
    HONG_KONG = "HK",
    HUNGARY = "HU",
    ICELAND = "IS",
    INDONESIA = "ID",
    IRAN = "IR",
    IRAQ = "IQ",
    ISRAEL = "IL",
    JAMAICA = "JM",
    JAPAN = "JP",
    JORDAN = "JO",
    KAZAKHSTAN = "KZ",
    KENYA = "KE",
    KIRIBATI = "KI",
    KOREA_NORTH = "KP",
    KOREA_SOUTH = "KR",
    KUWAIT = "KW",
    KYRGYZSTAN = "KG",
    LAO = "LA",
    LATVIA = "LV",
    LEBANON = "LB",
    LESOTHO = "LS",
    LIBERIA = "LR",
    LIBYA = "LY",
    LIECHTENSTEIN = "LI",
    LITHUANIA = "LT",
    LUXEMBOURG = "LU",
    MACAO = "MO",
    MADAGASCAR = "MG",
    MALAWI = "MW",
    MALAYSIA = "MY",
    MALDIVES = "MV",
    MALI = "ML",
    MALTA = "MT",
    MARSHALL_ISLANDS = "MH",
    MARTINIQUE = "MQ",
    MAURITANIA = "MR",
    MAURITIUS = "MU",
    MAYOTTE = "YT",
    MEXICO = "MX",
    MICRONESIA = "FM",
    MOLDOVA = "MD",
    MONACO = "MC",
    MONGOLIA = "MN",
    MONTENEGRO = "ME",
    MONTSERRAT = "MS",
    MOROCCO = "MA",
    MOZAMBIQUE = "MZ",
    MYANMAR = "MM",
    NAMIBIA = "NA",
    NAURU = "NR",
    NEPAL = "NP",
    NEW_CALEDONIA = "NC",
    NICARAGUA = "NI",
    NIGER = "NE",
    NIGERIA = "NG",
    NIUE = "NU",
    NORFOLK_ISLAND = "NF",
    NORTH_MACEDONIA = "MK",
    NORTHERN_MARIANA_ISLANDS = "MP",
    NORWAY = "NO",
    OMAN = "OM",
    PAKISTAN = "PK",
    PALAU = "PW",
    PALESTINE = "PS",
    PANAMA = "PA",
    PAPUA_NEW_GUINEA = "PG",
    PARAGUAY = "PY",
    PERU = "PE",
    PHILIPPINES = "PH",
    PITCAIRN = "PN",
    POLAND = "PL",
    PORTUGAL = "PT",
    PUERTO_RICO = "PR",
    QATAR = "QA",
    REUNION = "RE",
    ROMANIA = "RO",
    RUSSIAN_FEDERATION = "RU",
    RWANDA = "RW",
    SAINT_BARTHELEMY = "BL",
    SAINT_HELENA = "SH",
    SAINT_KITTS_AND_NEVIS = "KN",
    SAINT_LUCIA = "LC",
    SAINT_MARTIN = "MF",
    SAINT_PIERRE_AND_MIQUELON = "PM",
    SAINT_VINCENT_AND_THE_GRENADINES = "VC",
    SAMOA = "WS",
    SAN_MARINO = "SM",
    SAO_TOME_AND_PRINCIPE = "ST",
    SAUDI_ARABIA = "SA",
    SENEGAL = "SN",
    SERBIA = "RS",
    SEYCHELLES = "SC",
    SIERRA_LEONE = "SL",
    SINGAPORE = "SG",
    SINT_MAARTEN = "SX",
    SLOVAKIA = "SK",
    SLOVENIA = "SI",
    SOLOMON_ISLANDS = "SB",
    SOMALIA = "SO",
    SOUTH_AFRICA = "ZA",
    SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS = "GS",
    SOUTH_SUDAN = "SS",
    SPAIN = "ES",
    SRI_LANKA = "LK",
    SUDAN = "SD",
    SURINAME = "SR",
    SVALBARD_AND_JAN_MAYEN = "SJ",
    SWEDEN = "SE",
    SWITZERLAND = "CH",
    SYRIAN_ARAB_REPUBLIC = "SY",
    TAIWAN = "TW",
    TAJIKISTAN = "TJ",
    TANZANIA = "TZ",
    THAILAND = "TH",
    TIMOR_LESTE = "TL",
    TOGO = "TG",
    TOKELAU = "TK",
    TONGA = "TO",
    TRINIDAD_AND_TOBAGO = "TT",
    TUNISIA = "TN",
    TURKEY = "TR",
    TURKMENISTAN = "TM",
    TURKS_AND_CAICOS_ISLANDS = "TC",
    TUVALU = "TV",
    UGANDA = "UG",
    UKRAINE = "UA",
    UNITED_ARAB_EMIRATES = "AE",
    URUGUAY = "UY",
    UZBEKISTAN = "UZ",
    VANUATU = "VU",
    VENEZUELA = "VE",
    VIETNAM = "VN",
    VIRGIN_ISLANDS_BRITISH = "VG",
    VIRGIN_ISLANDS_US = "VI",
    WALLIS_AND_FUTUNA = "WF",
    WESTERN_SAHARA = "EH",
    YEMEN = "YE",
    ZAMBIA = "ZM",
    ZIMBABWE = "ZW"
}

const ZoneCountryToISOAlpha2Map: Record<ZoneCountry, string> = {
    [ZoneCountry.AUSTRALIA]: "AU",
    [ZoneCountry.NEW_ZEALAND]: "NZ",
    [ZoneCountry.UNITED_STATES]: "US",
    [ZoneCountry.UNITED_KINGDOM]: "GB",
    [ZoneCountry.INDIA]: "IN",
    [ZoneCountry.IRELAND] : "IE",
    [ZoneCountry.GERMANY] : "DE",
    [ZoneCountry.FRANCE] : "FR",
    [ZoneCountry.ITALY] : "IT",
    [ZoneCountry.NETEHERLANDS] : "NL",
    [ZoneCountry.AFGHANISTAN] : "AF",
    [ZoneCountry.ALAND_ISLANDS] : "AX",
    [ZoneCountry.ALBANIA] : "AL",
    [ZoneCountry.ALGERIA] : "DZ",
    [ZoneCountry.AMERICAN_SAMOA] : "AS",
    [ZoneCountry.ANDORRA] : "AD",
    [ZoneCountry.ANGOLA] : "AO",
    [ZoneCountry.ANGUILLA] : "AI",
    [ZoneCountry.ANTARCTICA] : "AQ",
    [ZoneCountry.ANTIGUA_AND_BARBUDA] : "AG",
    [ZoneCountry.ARGENTINA] : "AR",
    [ZoneCountry.ARMENIA] : "AM",
    [ZoneCountry.ARUBA] : "AW",
    [ZoneCountry.AUSTRIA] : "AT",
    [ZoneCountry.AZERBAIJAN] : "AZ",
    [ZoneCountry.BAHAMAS] : "BS",
    [ZoneCountry.BAHRAIN] : "BH",
    [ZoneCountry.BANGLADESH] : "BD",
    [ZoneCountry.BARBADOS] : "BB",
    [ZoneCountry.BELARUS] : "BY",
    [ZoneCountry.BELGIUM] : "BE",
    [ZoneCountry.BELIZE] : "BZ",
    [ZoneCountry.BENIN] : "BJ",
    [ZoneCountry.BERMUDA] : "BM",
    [ZoneCountry.BHUTAN] : "BT",
    [ZoneCountry.BOLIVIA] : "BO",
    [ZoneCountry.BONAIRE] : "BQ",
    [ZoneCountry.BOSNIA_AND_HERZEGOVINA] : "BA",
    [ZoneCountry.BOTSWANA] : "BW",
    [ZoneCountry.BOUVET_ISLAND] : "BV",
    [ZoneCountry.BRAZIL] : "BR",
    [ZoneCountry.BRITISH_INDIAN_OCEAN_TERRITORY] : "IO",
    [ZoneCountry.BRUNEI_DARUSSALAM] : "BN",
    [ZoneCountry.BULGARIA] : "BG",
    [ZoneCountry.BURKINA_FASO] : "BF",
    [ZoneCountry.BURUNDI] : "BI",
    [ZoneCountry.CABO_VERDE] : "CV",
    [ZoneCountry.CAMBODIA] : "KH",
    [ZoneCountry.CAMEROON] : "CM",
    [ZoneCountry.CANADA] : "CA",
    [ZoneCountry.CAYMAN_ISLANDS] : "KY",
    [ZoneCountry.CENTRAL_AFRICAN_REPUBLIC] : "CF",
    [ZoneCountry.CHAD] : "TD",
    [ZoneCountry.CHILE] : "CL",
    [ZoneCountry.CHINA] : "CN",
    [ZoneCountry.CHRISTMAS_ISLAND] : "CX",
    [ZoneCountry.COCOS_ISLANDS] : "CC",
    [ZoneCountry.COLOMBIA] : "CO",
    [ZoneCountry.COMOROS] : "KM",
    [ZoneCountry.CONGO] : "CG",
    [ZoneCountry.CONGO_DEMOCRATIC_REPUBLIC] : "CD",
    [ZoneCountry.COOK_ISLANDS] : "CK",
    [ZoneCountry.COSTA_RICA] : "CR",
    [ZoneCountry.COTE_DIVOIRE] : "CI",
    [ZoneCountry.CROATIA] : "HR",
    [ZoneCountry.CUBA] : "CU",
    [ZoneCountry.CURACAO] : "CW",
    [ZoneCountry.CYPRUS] : "CY",
    [ZoneCountry.CZECHIA] : "CZ",
    [ZoneCountry.DENMARK] : "DK",
    [ZoneCountry.DJIBOUTI] : "DJ",
    [ZoneCountry.DOMINICA] : "DM",
    [ZoneCountry.DOMINICAN_REPUBLIC] : "DO",
    [ZoneCountry.ECUADOR] : "EC",
    [ZoneCountry.EGYPT] : "EG",
    [ZoneCountry.EL_SALVADOR] : "SV",
    [ZoneCountry.EQUATORIAL_GUINEA] : "GQ",
    [ZoneCountry.ERITREA] : "ER",
    [ZoneCountry.ESTONIA] : "EE",
    [ZoneCountry.ESWATINI] : "SZ",
    [ZoneCountry.ETHIOPIA] : "ET",
    [ZoneCountry.FALKLAND_ISLANDS] : "FK",
    [ZoneCountry.FAROE_ISLANDS] : "FO",
    [ZoneCountry.FIJI] : "FJ",
    [ZoneCountry.FINLAND] : "FI",
    [ZoneCountry.FRENCH_GUIANA] : "GF",
    [ZoneCountry.FRENCH_POLYNESIA] : "PF",
    [ZoneCountry.FRENCH_SOUTHERN_TERRITORIES] : "TF",
    [ZoneCountry.GABON] : "GA",
    [ZoneCountry.GAMBIA] : "GM",
    [ZoneCountry.GEORGIA] : "GE",
    [ZoneCountry.GHANA] : "GH",
    [ZoneCountry.GIBRALTAR] : "GI",
    [ZoneCountry.GREECE] : "GR",
    [ZoneCountry.GREENLAND] : "GL",
    [ZoneCountry.GRENADA] : "GD",
    [ZoneCountry.GUADELOUPE] : "GP",
    [ZoneCountry.GUAM] : "GU",
    [ZoneCountry.GUATEMALA] : "GT",
    [ZoneCountry.GUERNSEY] : "GG",
    [ZoneCountry.GUINEA] : "GN",
    [ZoneCountry.GUINEA_BISSAU] : "GW",
    [ZoneCountry.HAITI] : "HT",
    [ZoneCountry.HEARD_ISLAND] : "HM",
    [ZoneCountry.HOLY_SEE] : "VA",
    [ZoneCountry.HONDURAS] : "HN",
    [ZoneCountry.HONG_KONG] : "HK",
    [ZoneCountry.HUNGARY] : "HU",
    [ZoneCountry.ICELAND] : "IS",
    [ZoneCountry.INDONESIA] : "ID",
    [ZoneCountry.IRAN] : "IR",
    [ZoneCountry.IRAQ] : "IQ",
    [ZoneCountry.ISRAEL] : "IL",
    [ZoneCountry.JAMAICA] : "JM",
    [ZoneCountry.JAPAN] : "JP",
    [ZoneCountry.JORDAN] : "JO",
    [ZoneCountry.KAZAKHSTAN] : "KZ",
    [ZoneCountry.KENYA] : "KE",
    [ZoneCountry.KIRIBATI] : "KI",
    [ZoneCountry.KOREA_NORTH] : "KP",
    [ZoneCountry.KOREA_SOUTH] : "KR",
    [ZoneCountry.KUWAIT] : "KW",
    [ZoneCountry.KYRGYZSTAN] : "KG",
    [ZoneCountry.LAO] : "LA",
    [ZoneCountry.LATVIA] : "LV",
    [ZoneCountry.LEBANON] : "LB",
    [ZoneCountry.LESOTHO] : "LS",
    [ZoneCountry.LIBERIA] : "LR",
    [ZoneCountry.LIBYA] : "LY",
    [ZoneCountry.LIECHTENSTEIN] : "LI",
    [ZoneCountry.LITHUANIA] : "LT",
    [ZoneCountry.LUXEMBOURG] : "LU",
    [ZoneCountry.MACAO] : "MO",
    [ZoneCountry.MADAGASCAR] : "MG",
    [ZoneCountry.MALAWI] : "MW",
    [ZoneCountry.MALAYSIA] : "MY",
    [ZoneCountry.MALDIVES] : "MV",
    [ZoneCountry.MALI] : "ML",
    [ZoneCountry.MALTA] : "MT",
    [ZoneCountry.MARSHALL_ISLANDS] : "MH",
    [ZoneCountry.MARTINIQUE] : "MQ",
    [ZoneCountry.MAURITANIA] : "MR",
    [ZoneCountry.MAURITIUS] : "MU",
    [ZoneCountry.MAYOTTE] : "YT",
    [ZoneCountry.MEXICO] : "MX",
    [ZoneCountry.MICRONESIA] : "FM",
    [ZoneCountry.MOLDOVA] : "MD",
    [ZoneCountry.MONACO] : "MC",
    [ZoneCountry.MONGOLIA] : "MN",
    [ZoneCountry.MONTENEGRO] : "ME",
    [ZoneCountry.MONTSERRAT] : "MS",
    [ZoneCountry.MOROCCO] : "MA",
    [ZoneCountry.MOZAMBIQUE] : "MZ",
    [ZoneCountry.MYANMAR] : "MM",
    [ZoneCountry.NAMIBIA] : "NA",
    [ZoneCountry.NAURU] : "NR",
    [ZoneCountry.NEPAL] : "NP",
    [ZoneCountry.NEW_CALEDONIA] : "NC",
    [ZoneCountry.NICARAGUA] : "NI",
    [ZoneCountry.NIGER] : "NE",
    [ZoneCountry.NIGERIA] : "NG",
    [ZoneCountry.NIUE] : "NU",
    [ZoneCountry.NORFOLK_ISLAND] : "NF",
    [ZoneCountry.NORTH_MACEDONIA] : "MK",
    [ZoneCountry.NORTHERN_MARIANA_ISLANDS] : "MP",
    [ZoneCountry.NORWAY] : "NO",
    [ZoneCountry.OMAN] : "OM",
    [ZoneCountry.PAKISTAN] : "PK",
    [ZoneCountry.PALAU] : "PW",
    [ZoneCountry.PALESTINE] : "PS",
    [ZoneCountry.PANAMA] : "PA",
    [ZoneCountry.PAPUA_NEW_GUINEA] : "PG",
    [ZoneCountry.PARAGUAY] : "PY",
    [ZoneCountry.PERU] : "PE",
    [ZoneCountry.PHILIPPINES] : "PH",
    [ZoneCountry.PITCAIRN] : "PN",
    [ZoneCountry.POLAND] : "PL",
    [ZoneCountry.PORTUGAL] : "PT",
    [ZoneCountry.PUERTO_RICO] : "PR",
    [ZoneCountry.QATAR] : "QA",
    [ZoneCountry.REUNION] : "RE",
    [ZoneCountry.ROMANIA] : "RO",
    [ZoneCountry.RUSSIAN_FEDERATION] : "RU",
    [ZoneCountry.RWANDA] : "RW",
    [ZoneCountry.SAINT_BARTHELEMY] : "BL",
    [ZoneCountry.SAINT_HELENA] : "SH",
    [ZoneCountry.SAINT_KITTS_AND_NEVIS] : "KN",
    [ZoneCountry.SAINT_LUCIA] : "LC",
    [ZoneCountry.SAINT_MARTIN] : "MF",
    [ZoneCountry.SAINT_PIERRE_AND_MIQUELON] : "PM",
    [ZoneCountry.SAINT_VINCENT_AND_THE_GRENADINES] : "VC",
    [ZoneCountry.SAMOA] : "WS",
    [ZoneCountry.SAN_MARINO] : "SM",
    [ZoneCountry.SAO_TOME_AND_PRINCIPE] : "ST",
    [ZoneCountry.SAUDI_ARABIA] : "SA",
    [ZoneCountry.SENEGAL] : "SN",
    [ZoneCountry.SERBIA] : "RS",
    [ZoneCountry.SEYCHELLES] : "SC",
    [ZoneCountry.SIERRA_LEONE] : "SL",
    [ZoneCountry.SINGAPORE] : "SG",
    [ZoneCountry.SINT_MAARTEN] : "SX",
    [ZoneCountry.SLOVAKIA] : "SK",
    [ZoneCountry.SLOVENIA] : "SI",
    [ZoneCountry.SOLOMON_ISLANDS] : "SB",
    [ZoneCountry.SOMALIA] : "SO",
    [ZoneCountry.SOUTH_AFRICA] : "ZA",
    [ZoneCountry.SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS] : "GS",
    [ZoneCountry.SOUTH_SUDAN] : "SS",
    [ZoneCountry.SPAIN] : "ES",
    [ZoneCountry.SRI_LANKA] : "LK",
    [ZoneCountry.SUDAN] : "SD",
    [ZoneCountry.SURINAME] : "SR",
    [ZoneCountry.SVALBARD_AND_JAN_MAYEN] : "SJ",
    [ZoneCountry.SWEDEN] : "SE",
    [ZoneCountry.SWITZERLAND] : "CH",
    [ZoneCountry.SYRIAN_ARAB_REPUBLIC] : "SY",
    [ZoneCountry.TAIWAN] : "TW",
    [ZoneCountry.TAJIKISTAN] : "TJ",
    [ZoneCountry.TANZANIA] : "TZ",
    [ZoneCountry.THAILAND] : "TH",
    [ZoneCountry.TIMOR_LESTE] : "TL",
    [ZoneCountry.TOGO] : "TG",
    [ZoneCountry.TOKELAU] : "TK",
    [ZoneCountry.TONGA] : "TO",
    [ZoneCountry.TRINIDAD_AND_TOBAGO] : "TT",
    [ZoneCountry.TUNISIA] : "TN",
    [ZoneCountry.TURKEY] : "TR",
    [ZoneCountry.TURKMENISTAN] : "TM",
    [ZoneCountry.TURKS_AND_CAICOS_ISLANDS] : "TC",
    [ZoneCountry.TUVALU] : "TV",
    [ZoneCountry.UGANDA] : "UG",
    [ZoneCountry.UKRAINE] : "UA",
    [ZoneCountry.UNITED_ARAB_EMIRATES] : "AE",
    [ZoneCountry.URUGUAY] : "UY",
    [ZoneCountry.UZBEKISTAN] : "UZ",
    [ZoneCountry.VANUATU] : "VU",
    [ZoneCountry.VENEZUELA] : "VE",
    [ZoneCountry.VIETNAM] : "VN",
    [ZoneCountry.VIRGIN_ISLANDS_BRITISH] : "VG",
    [ZoneCountry.VIRGIN_ISLANDS_US] : "VI",
    [ZoneCountry.WALLIS_AND_FUTUNA] : "WF",
    [ZoneCountry.WESTERN_SAHARA] : "EH",
    [ZoneCountry.YEMEN] : "YE",
    [ZoneCountry.ZAMBIA] : "ZM",
    [ZoneCountry.ZIMBABWE] : "ZW"
}

export const ZoneCountryProperties: EnumProperties[] = Object.entries(ZoneCountry).map(([key, value]) => ({
    displayName: key
        .replace(/_/g, " ")
        .split(" ")
        .map((word) => word.charAt(0) + word.slice(1).toLowerCase())
        .join(" "), // Converts to "Australia", "New Zealand", etc.
    key,
    value
}))

export const ZoneCountryOptions = () => {
    let options: any[] = [];
    ZoneCountryProperties.forEach((item: EnumProperties) => {
        options.push({ label: item.displayName, value: item.value });
    });
    return options;
}

export const ZoneCountryByDisplayName = (displayName: string) => {
    let status = ZoneCountryProperties.find((item: EnumProperties) => item.displayName === displayName);
    return status ? status.value : '';
}

export const ZoneCountryByValue = (value: string) => {
    let status = ZoneCountryProperties.find((item: EnumProperties) => item.value === value);
    return status ? status.displayName : '';
}

export const getISOAlpha2Code = (zoneCountry: ZoneCountry): string => {
    return ZoneCountryToISOAlpha2Map[zoneCountry]
}

export const checkIfPostcodesAvailable = (zoneCountry: ZoneCountry): boolean => {
    return zoneCountry === ZoneCountry.AUSTRALIA || zoneCountry === ZoneCountry.NEW_ZEALAND 
    || zoneCountry === ZoneCountry.UNITED_STATES || zoneCountry === ZoneCountry.UNITED_KINGDOM 
    || zoneCountry === ZoneCountry.INDIA || zoneCountry === ZoneCountry.IRELAND
    || zoneCountry === ZoneCountry.GERMANY || zoneCountry === ZoneCountry.FRANCE
    || zoneCountry === ZoneCountry.ITALY || zoneCountry === ZoneCountry.NETEHERLANDS
}