import React, { useEffect, useState } from "react"

import { DropdownOption } from "../../models/DropdownOption"
import { MasterPostCode, defaultPostCode, getDisplayValueByFieldType } from "../../models/MasterPostCode"

import { cn } from "../../utils/classNames"
import Dropdown from "../input/Dropdown"
import DynamicDropdown from "../input/DynamicDropdown"
import InputWrapper from "../input/wrapper/InputWrapper"
import { useSelector } from "react-redux"
import { useGetPostCodesQuery } from "../../api/WidgetQuoteAPI"
import { ZoneCountryByValue, ZoneCountryOptions, checkIfPostcodesAvailable } from "../../enum/ZoneCountry"
import { HelpPopupProps } from "../input/wrapper/InputLabelWrapper"

interface PostcodeDynamicDDProps {
    label?: string
    value?: string
    handleChange?: (postCode: MasterPostCode) => void
    error?: string
    subtitle?: string
    showLabel?: boolean
    fieldName: keyof MasterPostCode
    placeholder?: string
    countryCode?: string
    showHelpData?: HelpPopupProps
}

const PostcodeDynamicDD: React.FC<PostcodeDynamicDDProps> = (props) => {
    const [displayValue, setDisplayValue] = React.useState<string>(props.value || "")
    const [country, setCountry] = useState<DropdownOption>({
        value: props.countryCode,
        label: props.countryCode
    })
    
    useEffect(() => {
      setDisplayValue(props.value || "")
    }, [props.value])
    

    useEffect(() => {
        if (props.countryCode) {
            setCountry({ value: props.countryCode, label: props.countryCode })
        }
    }, [props.countryCode])

    const useQueryHook = (search: string) => {
        const {
            data: postCodes,
            error,
            isLoading,
            isFetching
        } = useGetPostCodesQuery({ query: search, fieldName: props.fieldName, countryCode: country.value })
        return { data: postCodes, isLoading, isFetching, error }
    }

    const usePostcodeHook = (search: string) => {
        return useQueryHook(search)
    }

    const handleChange = (postCode: MasterPostCode) => {
        if (props.handleChange) props.handleChange(postCode)
        setDisplayValue(getDisplayValueByFieldType(postCode, props.fieldName));
    }

    const handleCountryChange = (value: DropdownOption) => {
        setCountry(value)
        let displayValue = props.fieldName === "country" ? ZoneCountryByValue(value.value) : ""
        setDisplayValue(displayValue)
        if (props.handleChange) props.handleChange({ ...defaultPostCode, country: value.value })
    }

    return (
        <InputWrapper showHelpData={props.showHelpData} label={props.label} error={props.error} subtitle={props.subtitle}>
            <div className=" max-w-full w-full flex gap-1">
                <div className="flex-[0.3] max-w-[6.5rem]">
                    <Dropdown
                    showLabel={false}
                        countryDropdown
                        handleChange={handleCountryChange}
                        value={country}
                        options={ZoneCountryOptions()}
                    />
                </div>
                <div className={cn(" flex-1 max-w-[calc(100%-6.5rem)]")}>
                    <DynamicDropdown
                        showLabel={false}
                        queryHook={usePostcodeHook}
                        value={displayValue}
                        handleOnChange={handleChange}
                        getDisplayValue={(postCode) => getDisplayValueByFieldType(postCode, props.fieldName)}
                        placeholder={props.placeholder ?? ""}
                        disabled={!checkIfPostcodesAvailable(country.value)}
                    />
                </div>
            </div>
        </InputWrapper>
    )
}

export default PostcodeDynamicDD
