import React, { useEffect } from "react"
import QuoteLayout from "../../layouts/quoteLayout/QuoteLayout"
import QuoteWrapper from "../../components/quote/QuoteWrapper"
import { useGetWidgetQuery } from "../../api/WidgetQuoteAPI"
import { useParams } from "react-router-dom"
import ErrorPopup from "../../components/popup/ErrorPopup"

const QuotePage: React.FC = () => {
    const { widgetId } = useParams<{ widgetId: string }>()
    const { data, isLoading, isSuccess } = useGetWidgetQuery({ id: widgetId ?? "" })

    useEffect(() => {
        if (isSuccess) {
            let uiSettings = data.settingJson?.uiSettings;
            if (uiSettings?.primaryColor) {
                document.documentElement.style.setProperty("--primary-color", uiSettings?.primaryColor.hexCode)
            }
        }
    }, [data])

    return (
        <QuoteLayout>
            {isSuccess && <QuoteWrapper data={data} />}
            <ErrorPopup />
        </QuoteLayout>
    )
}

export default QuotePage
