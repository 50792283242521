import { useEffect, useRef, useState } from "react";
import { classNames } from "../../utils/classNames";
import UploadCloud02 from "@untitled-ui/icons-react/build/esm/UploadCloud02";
import Trash01 from "@untitled-ui/icons-react/build/esm/Trash01";
import excelIcon from "../../assets/icons/excel_file_icon.svg";
import ProgressBar from "../progressBar/ProgressBar";
import { formatFileSize } from "../../utils/formatFileSize";
import React from "react";
interface FileUploadProps {
  label?: string;
  onChange: (file: File | null) => void;
  file: File | null;
  uploadProgress?: number;
  error?: string;
  rightButton?: React.ReactNode;
  showProgressBar?: boolean;
  triggerDisableFormSubmit?: (disable: boolean) => void;
}
const FileUpload = ({ label, onChange, file, uploadProgress, error, rightButton, showProgressBar = false,triggerDisableFormSubmit }: FileUploadProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [currentFile, setCurrentFile] = useState<File | null>(file);

  useEffect(() => {
    setCurrentFile(file);
  }, [file]);

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const droppedFiles = e.dataTransfer.files;

    if (droppedFiles.length > 0) {
      handleOnFileChange(droppedFiles[0]);
    }
  };


  const handleOnFileChange = (file: File | null) => {
    setCurrentFile(file);
    onChange(file);
    triggerDisableFormSubmit && triggerDisableFormSubmit(true);
  }

  const handleRemoveFile = () => {
    inputRef.current!.value = "";
    setCurrentFile(null);
    onChange(null);
  }

  return (
      <div
          className="relative my-2"
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
      >
          <div className="flex justify-between items-center">
              <p
                  className={classNames(
                      !label ? "invisible" : "",
                      "font-medium text-text_secondary_color text-sm mb-2"
                  )}
              >
                  {label || "label"}
              </p>
              {rightButton && rightButton}
          </div>
          <div
              className={classNames(
                  error ? "border-2 border-primary_error" : "border border-border_primary",
                  "w-full flex justify-center items-center rounded-lg p-4"
              )}
          >
              <input
                  ref={inputRef}
                  title="upload csv"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  type="file"
                  className="hidden"
                  onChange={(e) => handleOnFileChange(e.target.files![0])}
              />
              {currentFile ? (
                  <div className="flex gap-3 w-full">
                      <img
                          src={excelIcon}
                          className="self-start"
                          alt="excel icon"
                      />
                      <div className="flex flex-col gap-1 w-full">
                          <div className="flex items-center justify-between">
                              <p className="text-sm font-medium text-text_tertiary_color">{currentFile.name}</p>
                              <Trash01
                                  className={classNames(
                                      error ? "text-primary_error" : "text-text_secondary_color",
                                      "w-5 h-5 cursor-pointer"
                                  )}
                                  onClick={handleRemoveFile}
                              />
                          </div>
                          {error ? (
                              <p className="text-sm font-normal text-text_secondary_color">{error}</p>
                          ) : (
                              <p className="text-sm font-normal text-text_secondary_color">
                                  {formatFileSize(currentFile.size)}
                              </p>
                          )}
                          {error ? (
                              <p
                                  onClick={() => {
                                      inputRef.current?.click()
                                  }}
                                  className="text-text_secondary_color font-medium text-sm"
                              >
                                  Try again
                              </p>
                          ) : (
                              showProgressBar && (
                                  <ProgressBar
                                      progress={uploadProgress || 0}
                                      type="primary"
                                  />
                              )
                          )}
                      </div>
                  </div>
              ) : (
                  <div
                      onClick={() => {
                          inputRef.current?.click()
                      }}
                      className="flex flex-col justify-center items-center gap-1"
                  >
                      <p className="text-base font-normal text-text_secondary_color">
                          <span className="text-base font-bold text-primary_color underline cursor-pointer">
                              Click to upload
                          </span>{" "}
                          or drag and drop
                      </p>
                      <p className="text-sm font-normal text-text_secondary_color">.csv, .xls or .xlsx files only (max. 20mb)</p>
                  </div>
              )}
          </div>
          {/* {error && (
              <div className="flex items-center gap-2 mt-2">
                  <p className="text-primary_error text-sm">{error}</p>
              </div>
          )} */}
      </div>
  )
};

export default FileUpload;
