export interface BulkQuoteRequest {
  businessName: string;
  fullName: string;
  mobileNumber: string;
  businessEmail: string;
  note: string;
}

export const defaultBulkQuoteRequest: BulkQuoteRequest = {
  businessName: "",
  fullName: "",
  mobileNumber: "",
  businessEmail: "",
  note: "",
};
