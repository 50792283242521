import CheckCircle from "@untitled-ui/icons-react/build/esm/CheckCircle";
import AlertCircle from "@untitled-ui/icons-react/build/esm/AlertCircle";
import Lightning01 from "@untitled-ui/icons-react/build/esm/Lightning01";
import { classNames } from "../../utils/classNames";

interface AlertBoxProps {
  type: "info" | "warning" | "success" | "error" | "ai";
  title?: string;
  message?: string;
}
const AlertBox = ({ title, message, type }: AlertBoxProps) => {
  return (
    <div
      className={classNames(
        type === "info" || type === "ai"
          ? "bg-background_primary"
          : type === "warning"
            ? "bg-warning_background"
            : type === "success"
              ? "bg-green_3"
              : "bg-error_50",
        "w-full rounded-[4px] p-4 flex items-start border gap-4 border-border_primary items-center"
      )}
    >
      <div
        className={classNames(
          type === "success"
            ? "border-green_2"
            : type === "error"
              ? "border-border_error"
              : type === "warning"
                ? "border-secondary_warning"
                : "border-border_secondary",
          "w-10 h-10 p-2 flex justify-center items-center rounded-md border"
        )}
      >
        {type === "success" ? (
          <CheckCircle className="text-success_primary w-5 h-5" />
        ) : type === "warning" ? (
          <AlertCircle className="text-primary_warning w-5 h-5" />
        ) : type === "info" ? (
          <AlertCircle className="text-primary_color w-5 h-5" />
        ) : type === "ai" ? (
          <Lightning01 className="text-primary_color w-5 h-5" />
        ) : (
          <AlertCircle className="text-primary_error w-5 h-5" />
        )}
      </div>
      {
        <div className="flex flex-col">
          <p className="font-semibold text-sm text-text_tertiary_color">
            {title}
          </p>
          {message && <p className="font-normal text-sm text-text_secondary_color">
            {message}
          </p>}
        </div>}
    </div>
  );
};

export default AlertBox;
