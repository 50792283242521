import { EnumProperties } from "../models/EnumProperties";

export enum WeightUnits {
    KG = 0,
    LB = 1
}

export const WeightUnitsProperties: EnumProperties[] = [
    {
        displayName: "Kilogram (kg)",
        key: "kg",
        value: WeightUnits.KG
    },
    {
        displayName: "Pound (lbs)",
        key: "lbs",
        value: WeightUnits.LB
    }
]

export const WeightUnitsOptions = () => {
    let options: any[] = [];
    WeightUnitsProperties.forEach((item: EnumProperties) => {
        options.push({ label: item.displayName, value: item.value });
    });
    return options;
}

export const WeightUnitsByDisplayName = (displayName: string) => {
    let status = WeightUnitsProperties.find((item: EnumProperties) => item.displayName === displayName);
    return status ? status.value : '';
}

export const WeightUnitsByValue = (value: WeightUnits) => {
    let unit = WeightUnitsProperties.find((item: EnumProperties) => item.value === value);
    return unit ? unit.displayName : '';
}

export const WeightUnitsKeyByValue = (value: WeightUnits) => {
    let unit = WeightUnitsProperties.find((item: EnumProperties) => item.value === value);
    return unit ? unit.key : 'kg';
}