import React, { useEffect, useState } from "react";
import { classNames, cn } from "../../utils/classNames";
import InputWrapper from "./wrapper/InputWrapper";

interface InputProps {
  type?: string;
  label?: string;
  handleChange: (value: any) => void;
  placeholder?: string;
  multiline?: boolean;
  rows?: number;
  error?: string;
  value?: any;
  disabled?: boolean;
  subtitle?: string;
  icon?: React.ReactNode;
  maxLength?: number;
  iconRTL?: boolean;
  iconAction?: () => void;
  iconTooltip?: string;
  showLabel?: boolean;
  onBlurEvent?: () => void;
}

const Input = ({
  handleChange,
  type = "text",
  placeholder,
  label,
  multiline,
  rows = 3,
  error,
  value,
  disabled,
  subtitle,
  icon,
  maxLength = 255,
  iconRTL = false,
  iconAction,
  iconTooltip,
  showLabel = true,
  onBlurEvent,
}: InputProps) => {
  const [currentValue, setCurrentValue] = useState<string>(value || "");
  const onChange = (e: any) => {
    setCurrentValue(e.target.value);
    handleChange(e.target.value);
  };

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);
  const showIcon = () => {
    if (error && iconRTL) {
      return false;
    }
    return icon;
  };

  return (
      <InputWrapper
          label={label}
          showLabel={showLabel}
          subtitle={subtitle}
          error={error}
          // showErrorIcon={true}
      >
          {multiline ? (
              <textarea
                  typeof={type}
                  disabled={disabled}
                  value={currentValue}
                  placeholder={placeholder}
                  rows={rows}
                  onChange={onChange}
                  className={cn(
                      error && "border-primary_error focus:border-primary_error focus:outline-primary_error",
                      "border  rounded-lg p-2  w-full border-border_primary focus:border-primary_color focus:outline-none"
                  )}
              />
          ) : (
              <div className="flex items-center relative">
                  {showIcon() && (
                      <div
                          onClick={iconAction ? iconAction : () => {}}
                          title={iconTooltip || ""}
                          className={classNames(
                              iconRTL ? "right-0 pr-3" : "left-0 pl-3",
                              iconAction ? "cursor-pointer" : "",
                              "absolute inset-y-0 flex items-center text-blue_1"
                          )}
                      >
                          {icon}
                      </div>
                  )}
                  <input
                      disabled={disabled}
                      type={type}
                      value={currentValue}
                      placeholder={placeholder}
                      onChange={onChange}
                      onBlur={onBlurEvent ? onBlurEvent : () => {}}
                      maxLength={maxLength}
                      className={cn(
                          "border rounded-lg py-3 px-3  focus:outline-none w-full border-border_primary focus:border-primary_color placeholder:text-light_gray",
                          icon && !iconRTL ? "pl-10" : "",
                          icon && iconRTL ? "pr-10" : "",
                          error && "border-primary_error focus:border-primary_error focus:outline-primary_error",
                      )}
                  />
              </div>
          )}
      </InputWrapper>
  )
};

export default Input;
