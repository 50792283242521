import { BuildingType } from "../enum/BuildingType";
import { MasterPostCode } from "./MasterPostCode";

export interface Sender {
    shipperPostalCode: string;
    shipperIdentityPostcode: string;
    shipperAreaCode: string;
    shipperAreaName: string;
    shipperCity: string;
    shipperState: string;
    shipperRegionName: string;
    shipperRegionCode: string;
    shipperCountryCode: string;
    shipperCompanyName: string;
    shipperContactName: string;
    shipperBuildingType: BuildingType;
    shipperPhone: string;
    shipperEmail: string;
    shipperStreet1: string;
    shipperStreet2: string;
    shipperStreet3: string;
    shipperInstructions: string;
    shipperOpens: string;
    shipperCloses: string;
    shipperLocationType: string;
    shipperRequiresTailLift: boolean;
    shipperStateName: string;
    shipperCityName: string;
    shipperSuburbName: string;
    shipperLat: string;
    shipperLong: string;
}

export const defaultSender: Sender = {
    shipperPostalCode: "",
    shipperIdentityPostcode: "",
    shipperAreaCode: "",
    shipperAreaName: "",
    shipperCity: "",
    shipperState: "",
    shipperRegionName: "",
    shipperRegionCode: "",
    shipperCountryCode: "AU",
    shipperCompanyName: "",
    shipperContactName: "",
    shipperBuildingType: BuildingType.COMMERCIAL,
    shipperPhone: "",
    shipperEmail: "",
    shipperStreet1: "",
    shipperStreet2: "",
    shipperStreet3: "",
    shipperInstructions: "",
    shipperOpens: "",
    shipperCloses: "",
    shipperLocationType: "",
    shipperRequiresTailLift: false,
    shipperStateName: "",
    shipperCityName: "",
    shipperSuburbName: "",
    shipperLat:"",
    shipperLong:""
}

export function getSenderByMasterCode(masterCode: MasterPostCode) : Sender {
    return {
        shipperPostalCode: masterCode.postcode,
        shipperIdentityPostcode : masterCode.identityPostcode,
        shipperAreaCode: masterCode.areaCode,
        shipperAreaName: masterCode.areaName,
        shipperCity: masterCode.cityCode,
        shipperState: masterCode.stateCode,
        shipperRegionName: masterCode.region,
        shipperRegionCode: masterCode.regionCode,
        shipperCountryCode: masterCode.country,
        shipperCompanyName: "",
        shipperContactName: "",
        shipperBuildingType: BuildingType.COMMERCIAL,
        shipperPhone: "",
        shipperEmail: "",
        shipperStreet1: masterCode.identityPostcode,
        shipperStreet2: "",
        shipperStreet3: "",
        shipperInstructions: "",
        shipperOpens: "",
        shipperCloses: "",
        shipperLocationType: "",
        shipperRequiresTailLift: false,
        shipperStateName: masterCode.stateName,
        shipperCityName: masterCode.cityName,
        shipperSuburbName: masterCode.suburbName,
        shipperLat: masterCode.latGoogle,
        shipperLong: masterCode.longGoogle
    }
}