export function validateEmail(email: string): boolean {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export const capitalizeFirstLetter = (input: string): string => {
    if (!input) return input

    return input
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(" ")
}

export const getCurrencySymbolUtil = (currency: string) => {
  if (currency) {
    if (currency === CurrencyEnum.AUD || currency === CurrencyEnum.USD) {
      return '$';
    } else if (currency === CurrencyEnum.INR) {
      return '₹';
    } else if (currency === CurrencyEnum.GBP) {
      return '£';
    }
  }
  return '$';
}


export enum CurrencyEnum {
  AUD = "AUD",
  USD = "USD",
  INR = "INR",
  NZD = "NZD",
  GBP = "GBP"
  // CAD = "CAD",
}