import React from "react";

interface Props {
  children: React.ReactNode;
}

const QuoteLayout = ({ children }: Props) => {
  return (
    <div className="lg:flex ">
      <div className="w-full flex items-center">
        <div className="w-full">
          {children}
        </div>
      </div>
    </div>
  );
};

export default QuoteLayout;
