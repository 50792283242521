import Dotpoints01 from "@untitled-ui/icons-react/build/esm/Dotpoints01";
import VideoRecorder from "@untitled-ui/icons-react/build/esm/VideoRecorder";

interface SubTitleProps {
  description: string | JSX.Element;
  size?: "sm" | "md" | "lg";
  showVideo?: boolean;
  videoId?: string;
}

const SubTitle = ({ description, size = "md", showVideo, videoId = "watch_video" }: SubTitleProps) => {


  const getVideoContent = () => {
    return (
      <>
        <p className="font-normal text-text_secondary_color">&#x2022;</p>
        <div className="flex items-center gap-1 cursor-pointer" id={videoId}>
          <VideoRecorder className="w-5 h-5 text-secondary_color" /> <p className="font-normal text-sm text-secondary_color">How it Works?</p>
        </div>
      </>
    )
  }

  return (
    <div className="flex gap-2 items-center">
      <p
        className={`font-normal text-text_secondary_color ${size === "sm" ? "text-sm" : "text-base"
          }`}
      >
        {description}
      </p>
      {showVideo && getVideoContent()}
    </div>
  );
};

export default SubTitle;
