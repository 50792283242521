import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery, setQueryParam } from "./API";
import { ListAPIResponse } from "../models/ListAPIResponse";
import { FileUploadParams, MasterPostCodeParams } from "../models/QueryParams";
import { MasterPostCode } from "../models/MasterPostCode";
import { TestRateCardDTO } from "../models/TestRateCardDTO";
import { Widget } from "../models/Widget";
import { Carrier } from "../models/Carrier";

export const WidgetQuoteAPI = createApi({
    reducerPath: "quoteAPI",
    baseQuery: baseQuery,
    tagTypes: ["MasterPostCode"],
    endpoints: (builder) => ({
        getPostCodes: builder.query<ListAPIResponse<MasterPostCode>, MasterPostCodeParams>({
            query: (query: MasterPostCodeParams) => {
                let url = `api/widget/postcode/search`
                if (query) {
                    url = url + `?query=${query.query}`
                }
                if (query.fieldName) {
                    url = url + `&fieldName=${query.fieldName}`
                }
                if (query.countryCode) {
                    url = url + `&countryCode=${query.countryCode}`;
                }
                return url
            }
        }),
        getQuote: builder.mutation<void, TestRateCardDTO>({
            query: (body: TestRateCardDTO) => ({
                url: `api/widget/get-quote`,
                method: "POST",
                body: body
            })
        }),
        uploadBulkQuoteRequest: builder.mutation<void, FileUploadParams>({
            query: (formData: FileUploadParams) => ({
                url: `api/widget/${formData.id}/bulkQuote`,
                method: "POST",
                body: formData.data
            })
        }),

        getWidget: builder.query<Widget & { carrier: Carrier }, { id: string }>({
            //NOTE: change any afterwards
            query: ({ id }) => ({
                url: `/api/widget/${id}` //NOTE: replace with url
            })
        }),

        getUserInputSurchargeRules: builder.query<any[], any>({
            query: (data: any) => {
                let url = `api/widget/surcharge/userInputSurcharges?`;
                if (data.rateContractId) {
                    url += `rateContractId=${data.rateContractId}&`;
                }
                if (data.rateCardId) {
                    url += `rateCardId=${data.rateCardId}&`;
                }
                if (data.shipperId) {
                    url += `shipperId=${data.shipperId}&`;
                }
                return {
                    url: url,
                    method: "GET",
                }
            },
            keepUnusedDataFor: 0,
        }),
    })
})

export const { useGetPostCodesQuery, useLazyGetPostCodesQuery, useGetQuoteMutation, useUploadBulkQuoteRequestMutation, useGetWidgetQuery, useGetUserInputSurchargeRulesQuery } = WidgetQuoteAPI;
