import React, { useEffect, useState } from 'react'
import Button from '../../button/Button'

import Plus from "@untitled-ui/icons-react/build/esm/Plus"
import ChevronUp from '@untitled-ui/icons-react/build/esm/ChevronUp'
import Popup from '../../popup/Popup'
import PillToggle from '../../common/PillToggle'
import { Carrier } from '../../../models/Carrier'
import { getCurrencySymbolUtil } from '../../../utils/CommonUtil'

type Props = {
    value: string[],
    setValue: (value: string[]) => void,
    surcharges: any[] | undefined,
    carrier: Carrier
}

const PillToggleList = ({ value, setValue, surcharges, carrier }: Props) => {

    const [isListVisible, setIsListVisible] = useState(false)
    const [activePills, setActivePills] = useState<string[]>([])
    const [data, setData] = useState<any[]>([])

    useEffect(() => {
        if (surcharges) {
            setData(surcharges)
        }
    }, [surcharges])

    useEffect(() => {
        setValue(activePills)
    }, [activePills])

    const getSurchargeName = (surcharge: any) => {
        let name = surcharge.name;
        if (surcharge.eventSettings.valueType === "FLAT_COST") {
            name = name + " (" + surcharge.eventSettings.value + "" + getCurrencySymbolUtil(carrier.currency) + ")"
        } else {
            name = name + " (" + surcharge.eventSettings.value + "%)"
        }
        return name;
    }

    const renderContent = () => (
        <div className="w-full flex flex-wrap gap-2 mt-4">
            {data.map((item, index) => (
                <PillToggle key={item.id} isChecked={activePills.includes(item.id)} onChange={(value) => setActivePills(prev => value ? [...prev, item.id] : prev.filter(id => id !== item.id))} helpDescription={item.description}>
                    {getSurchargeName(item)}
                </PillToggle>
            ))}
        </div>
    )

    return (
        <>
            <div className="flex items-center gap-2">
                <Button
                    buttonType={6}
                    className="!text-sm font-semibold w-fit !text-primary_color"
                    icon={<Plus className="w-5 h-5" />}
                    label={"Additional Services"}
                    onClick={() => setIsListVisible((prev) => !prev)}
                />
                {activePills.length > 0 && <p className=" text-sm font-normal text-text_secondary_color">({data.find(item => item.id === activePills[0])?.name}{activePills.length > 1 ? ` & ${activePills.length - 1} more` : ""})</p>}
            </div>

            <Popup
                isOpen={isListVisible}
                title="Additional Services"
                message='Select any additional services you require for your shipment. Please note that additional services may incur additional cost that may or may not be fully disclosed below.'
                type="info"
                onClosePopUp={() => {
                    setIsListVisible(false)
                }}
                primaryButtonLabel="Save"
                primaryButtonAction={() => {
                    setValue(activePills)
                    setIsListVisible(false)
                }}
                content={renderContent()}
            />
        </>
    )
}

export default PillToggleList