import React, { useEffect, useRef, useState } from "react"

import { cn } from "../../utils/classNames"
import { createPortal } from "react-dom"

interface TooltipProps {
    children: React.ReactNode
    content: React.ReactNode
    side?: "top" | "bottom" | "left" | "right"
    sideOffset?: number
    containerClassName?: string
    delay?: number
    hideTooltip?: boolean
    disableCheckOverflow?: boolean
}

const Tooltip: React.FC<TooltipProps> = ({
    children,
    content,
    side = "top",
    sideOffset = 8,
    containerClassName,
    delay = 300,
    hideTooltip,
    disableCheckOverflow
}) => {
    const [isVisible, setIsVisible] = useState(false)
    const [tooltipPosition, setTooltipPosition] = useState<{ top: number; left: number }>({ top: 0, left: 0 })
    const [isContentOverflowing, setIsContentOverflowing] = useState(false)
    const tooltipRef = useRef<HTMLDivElement>(null)
    const childRef = useRef<HTMLDivElement>(null)
    const showTimeout = useRef<NodeJS.Timeout | null>(null)

    useEffect(() => {
        const handleResize = () => {
            if (isVisible) {
                calculatePosition()
            }
        }

        window.addEventListener("resize", handleResize)
        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [isVisible])

    useEffect(() => {
        if (isVisible) {
            calculatePosition()
        }
    }, [isVisible])

    useEffect(() => {
        checkOverflow()
        window.addEventListener("resize", checkOverflow)

        return () => window.removeEventListener("resize", checkOverflow)
    }, [content])

    const checkOverflow = () => {
        if (disableCheckOverflow) {
            setIsContentOverflowing(true)
            return
        }
        if (!childRef.current) return
        const offsetWidth = childRef.current.offsetWidth
        const scrollWidth = childRef.current.scrollWidth

        if (offsetWidth < scrollWidth) {
            setIsContentOverflowing(true)
        } else {
            setIsContentOverflowing(false)
        }
    }

    const handleMouseEnter = () => {
        showTimeout.current = setTimeout(() => {
            setIsVisible(true)
        }, delay)
    }

    const handleMouseLeave = () => {
        if (showTimeout.current) {
            clearTimeout(showTimeout.current)
        }
        setIsVisible(false)
    }

    const calculatePosition = () => {
        if (!childRef.current || !tooltipRef.current) return

        const childRect = childRef.current.getBoundingClientRect()
        const tooltipRect = tooltipRef.current.getBoundingClientRect()

        let top = 0
        let left = 0

        switch (side) {
            case "top":
                top = childRect.top - tooltipRect.height - sideOffset
                left = childRect.left + (childRect.width - tooltipRect.width) / 2
                break
            case "bottom":
                top = childRect.bottom + sideOffset
                left = childRect.left + (childRect.width - tooltipRect.width) / 2
                break
            case "left":
                top = childRect.top + (childRect.height - tooltipRect.height) / 2
                left = childRect.left - tooltipRect.width - sideOffset
                break
            case "right":
                top = childRect.top + (childRect.height - tooltipRect.height) / 2
                left = childRect.right + sideOffset
                break
            default:
                break
        }

        setTooltipPosition({
            top: top + window.scrollY, // Adjust for window scroll
            left: left + window.scrollX // Adjust for window scroll
        })
    }

    return (
        <div
            ref={childRef}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className={cn(" overflow-hidden truncate", containerClassName)}
        >
            {children}
            {!hideTooltip && isVisible && isContentOverflowing
                ? createPortal(
                      <div
                          ref={tooltipRef}
                          style={{
                              position: "fixed",
                              top: tooltipPosition.top,
                              left: tooltipPosition.left,
                              zIndex: 9999,
                              background: "#333",
                              color: "#fff",
                              padding: "8px",
                              borderRadius: "4px",
                              fontSize: "12px",
                              lineHeight: "1.4",
                              maxWidth: 500,
                              display: "block",
                              wordWrap: "break-word"
                          }}
                      >
                          {content}
                      </div>,
                      document.body
                  )
                : null}
        </div>
    )
}

export default Tooltip
