import { EnumProperties } from "../models/EnumProperties";

export enum BuildingType {
    RESIDENTIAL = 'Residential',
    COMMERCIAL = 'Commercial',
}

export const BuildingTypeProperties : EnumProperties[] = [
    {
        displayName: "Residential",
        key: "RESIDENTIAL",
        value: BuildingType.RESIDENTIAL
    },
    {
        displayName: "Commercial",
        key: "COMMERCIAL",
        value: BuildingType.COMMERCIAL
    }
]

export const BuildingTypeOptions = () => {
    let options: any[] = [];
    BuildingTypeProperties.forEach((item: EnumProperties) => {
        options.push({ label: item.displayName, value: item.value });
    });
    return options;
}

export const BuildingTypeByDisplayName = (displayName: string) => {
    let status = BuildingTypeProperties.find((item: EnumProperties) => item.displayName === displayName);
    return status ? status.value : '';
}

export const BuildingTypeByValue = (value: BuildingType) => {
    let unit = BuildingTypeProperties.find((item: EnumProperties) => item.value === value);
    return unit ? unit.displayName : '';
}