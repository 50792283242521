import { ShipmentType } from "../enum/ShipmentType";
import { Commodity, defaultCommodity } from "./Commodity";

export interface RateItem {
  goodsType: ShipmentType;
  goodsQuantity?: number;
  goodsLength?: number;
  goodsWidth?: number;
  goodsHeight?: number;
  goodsItemWeight?: number;
  commodityList: Commodity[];
  commodityExists : boolean;
}

export const defaultRateItem: RateItem = {
  goodsType: ShipmentType.PALLET,
  // goodsQuantity: 0,
  // goodsLength: 0,
  // goodsWidth: 0,
  // goodsHeight: 0,
  // goodsItemWeight: 0,
  commodityList: [],
  commodityExists : false
};
