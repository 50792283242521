import { EnumProperties } from "../models/EnumProperties";

export const YesNoProperties: EnumProperties[] = [
    {
        displayName: "Yes",
        key: "YES",
        value: true
    },
    {
        displayName: "No",
        key: "NO",
        value: false
    }
]

export const YesNoOptions = () => {
    let options: any[] = [];
    YesNoProperties.forEach((item: EnumProperties) => {
        options.push({ label: item.displayName, value: item.value });
    });
    return options;
}

export const YesNoByValue = (value: boolean) => {
    let displayName = "";
    YesNoProperties.forEach((item: EnumProperties) => {
        if (item.value === value) {
            displayName = item.displayName;
        }
    });
    return displayName;
}