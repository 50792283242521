export const formatFileSize = (sizeInBytes: number) => {
  const KB_THRESHOLD = 1024; // 1 KB = 1024 Bytes
  const MB_THRESHOLD = 1024 * KB_THRESHOLD; // 1 MB = 1024 KB

  if (sizeInBytes >= MB_THRESHOLD) {
    // If the file size is larger than or equal to 1 MB, display in MB
    const sizeInMB = sizeInBytes / MB_THRESHOLD;
    return `${sizeInMB.toFixed(2)} MB`;
  } else {
    // If the file size is less than 1 MB, display in KB
    const sizeInKB = sizeInBytes / KB_THRESHOLD;
    return `${sizeInKB.toFixed(2)} KB`;
  }
};
