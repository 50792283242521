import { useDispatch } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";

import { WidgetQuoteAPI } from "../api/WidgetQuoteAPI";
import ErrorPopupSlice from "./features/ErrorPopupSlice";
import { rtkQueryErrorLogger } from "./rtkQueryErrorLogger";

export const store = configureStore({
  reducer: {
    errorPopup: ErrorPopupSlice,
    [WidgetQuoteAPI.reducerPath]: WidgetQuoteAPI.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
        WidgetQuoteAPI.middleware,
        rtkQueryErrorLogger
    ),
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<AppDispatch>();
