import { useEffect, useRef, useState } from "react";
import { classNames, cn } from "../../utils/classNames";
import AlertCircle from "@untitled-ui/icons-react/build/esm/AlertCircle";
import { ListAPIResponse } from "../../models/ListAPIResponse";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";

import SearchLg from "@untitled-ui/icons-react/build/esm/SearchLg";
import ChevronDown from "@untitled-ui/icons-react/build/esm/ChevronDown";
import Check from "@untitled-ui/icons-react/build/esm/Check";
import { SpinnerCircularFixed } from "spinners-react";
import ErrorMessage from "./ErrorMessage";
import InputWrapper from "./wrapper/InputWrapper";
import Plus from "@untitled-ui/icons-react/build/esm/Plus";
import { HelpPopupProps } from "./wrapper/InputLabelWrapper";
import Tooltip from "../ui/Tooltip";

interface props<T> {
  label?: string;
  value: string | undefined;
  error?: string;
  subtitle?: string;
  queryHook: (search: string) => {
    data: ListAPIResponse<T> | undefined | any;
    isLoading: boolean;
    isFetching: boolean;
    error: FetchBaseQueryError | SerializedError | undefined;
  };
  handleOnChange: (value: T) => void;
  getDisplayValue: (object: T) => string;
  placeholder?: string;
  icon?: React.ReactNode;
  enableCreateNew?: boolean;
  onCreateNew?: () => void;
  showHelpData?: HelpPopupProps
  showLabel?: boolean
  disabled?: boolean
}

const DynamicDropdown = <T,>(props: props<T>) => {
  const { value = "" } = props;
  const dropdownRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [currentValue, setCurrentValue] = useState<string>("");
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const [dropdownOptions, setDropdownOptions] = useState<T[]>([]);
  const [isFocusActice, setIsFocusActice] = useState<boolean>(false);
  const { data: queryData, isLoading, isFetching, error } = props.queryHook(currentValue);

  useEffect(() => {
    if (queryData) {
      setDropdownOptions(queryData);
    }
  }, [queryData]);

  useEffect(() => {
    if (!openDropdown) {
      setCurrentValue("");
    }
  }, [openDropdown]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && openDropdown) {
        setOpenDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };

  }, [openDropdown]);

  useEffect(() => {
    const handleKeyDown = (e: any) => {
      if (openDropdown) {
        if (e.key === "ArrowDown") {
          const index = dropdownOptions.findIndex((option) => props.getDisplayValue(option) === props.value);
          if (index < dropdownOptions.length - 1) {
            props.handleOnChange(dropdownOptions[index + 1]);
          }
        }
        if (e.key === "ArrowUp") {
          const index = dropdownOptions.findIndex((option) => props.getDisplayValue(option) === props.value);
          if (index > 0) {
            props.handleOnChange(dropdownOptions[index - 1]);
          }
        }
        if (e.key === "Enter") {
          setOpenDropdown(!openDropdown);
        }
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [openDropdown, props.value]);

  useEffect(() => { }, [currentValue]);

  const onSearchTextChange = (e: any) => {
    setCurrentValue(e.target.value);
  };

  const onSelect = (option: T) => {
    setOpenDropdown(false);
    setDropdownOptions([]);
    props.handleOnChange(option);
  };

  const onCreateNewClick = () => {
    setOpenDropdown(false);
    setDropdownOptions([]);
    if (props.onCreateNew) {
      props.onCreateNew();
    }
  };

  const getEmptyListContent = () => {
    if (props.enableCreateNew) {
      return (
        <div
          key={"Create_new"}
          className={" py-2 px-2.5 hover:bg-background_primary cursor-pointer flex items-center"}
          onClick={onCreateNewClick}
        >
          <Plus className="w-5 h-5 text-secondary_color" />
          <p className="font-medium text-md p-2 text-secondary_color hover:bg-background_primary"> Create New</p>
        </div>
      )
    }
    return (
      <div
        key={"No_data"}
        className={" py-2 px-2.5 hover:bg-background_primary cursor-pointer flex gap-2"}
      >
        <p className="font-normal text-base text-text_primary_color italic">No data</p>
      </div>
    )
  }

  return (
      <InputWrapper
          ref={dropdownRef}
          showHelpData={props.showHelpData}
          label={props.label}
          subtitle={props.subtitle}
          error={props.error}
                      showLabel={props.showLabel}

          >
          <div
              onClick={() => {
                  if (props.disabled) return;
                  setOpenDropdown(!openDropdown)
              }}
              tabIndex={0}
              onKeyPress={(e) => {
                  if (e.key === "Enter" || e.key === " " || e.key === "Tab") {
                      setOpenDropdown(!openDropdown)
                  }
              }}
              className={cn(
                  "flex items-center justify-between py-3 px-3 border bg-white rounded-lg cursor-pointer border-border_primary focus:border-primary_color focus:outline-primary_color",
                  props.error && "border-primary_error focus:border-primary_error focus:outline-primary_error",
                  props.disabled ? "cursor-not-allowed" : "cursor-pointer",
              )}>
              {!props.value?.length ? (
                  <p className=" text-base font-normal text-light_gray truncate w-full">
                    Type suburb or postcode
                  </p>
              ) : (
                  <p className="font-normal text-base text-text_primary_color truncate w-full">
                      <Tooltip
                          content={props.value}
                          side="top">
                          {props.value}
                      </Tooltip>
                  </p>
              )}
              <div>
                  <ChevronDown className="w-5 h-5 text-light_gray" />
              </div>
          </div>
          {openDropdown && (
              <div
                  className={cn(
                      props.error || props.subtitle ? "top-[72%]" : "top-[102%]",
                      "absolute left-0 w-full max-h-80 overflow-auto bg-white rounded-lg border border-border_primary z-10"
                  )}>
                  <div className="flex items-center gap-4 border-b border-border_secondary p-2">
                      <SearchLg className="w-5 h-5 text-dark_gray" />
                      <input
                          ref={inputRef}
                          type="text"
                          placeholder={props.placeholder || "Search"}
                          className="w-full outline-none border-none focus:outline-none focus:border-none placeholder:text-light_gray"
                          value={currentValue}
                          onChange={onSearchTextChange}
                      />
                  </div>
                  {dropdownOptions.length === 0 && currentValue.length == 0 && (
                      <div
                          key={"empty"}
                          className={" py-2 px-2.5 hover:bg-background_primary cursor-pointer flex gap-2"}>
                          <p className="font-normal text-base text-text_secondary_color italic">
                              Search to get results
                          </p>
                      </div>
                  )}
                  {dropdownOptions.length === 0 && currentValue.length > 0 && (isLoading || isFetching) && (
                      <div
                          key={"loading"}
                          className={" py-2 px-2.5 hover:bg-background_primary cursor-pointer flex gap-2"}>
                          <SpinnerCircularFixed
                              size={20}
                              thickness={150}
                              speed={100}
                              color="rgb(14 10 77 / var(--tw-bg-opacity))"
                              secondaryColor="rgba(0,0,0,0.44)"
                          />
                          <p className="font-normal text-base text-text_secondary_color italic">Searching</p>
                      </div>
                  )}
                  {dropdownOptions.length === 0 &&
                      currentValue.length > 0 &&
                      !isLoading &&
                      !isFetching &&
                      getEmptyListContent()}
                  {dropdownOptions.map((option, index) => (
                      <div
                          key={index}
                          className={classNames(
                              props.getDisplayValue(option) === props.value ? "bg-background_primary" : "",
                              "py-2 px-2.5 hover:bg-background_primary cursor-pointer flex items-center justify-between"
                          )}
                          onClick={() => {
                              onSelect(option)
                          }}>
                          <p className="font-normal text-base text-text_primary_color">
                              {props.getDisplayValue(option)}
                          </p>
                          {props.getDisplayValue(option) === props.value && (
                              <Check className="w-4 h-4 text-primary_color" />
                          )}
                      </div>
                  ))}
              </div>
          )}
      </InputWrapper>
  )
};

export default DynamicDropdown;
