import { EnumProperties } from "../models/EnumProperties";

export enum ShipmentType {
    ANY = "Any",
    CATON_BOX = "Carton/Box",
    SKID = "Skid",
    PALLET = "Pallet",
    TUBE = "Tube",
    CRATE = "Crate",
    SATCHEL = "Satchel",
    BAG = "Bag",
    ENVELOPE = "Envelope",
    OTHER = "Other/Misc",
    UNPACKAGED = "Unpackaged",
    A_TRAILER = "A-Trailer",
    B_TRAILER = "B-Trailer",
    B_DOUBLE = "B-Double",
    DROP_DECK_TRAILER = "Drop Deck Trailer",
    SINGLE_TRAILER = "Single Trailer",
}

export const ShipmentTypeProperties: EnumProperties[] = [
    // {
    //     displayName: "Any",
    //     key: "ANY",
    //     value: ShipmentType.ANY
    // },
    {
        displayName: "Carton/Box",
        key: "CATON_BOX",
        value: ShipmentType.CATON_BOX
    },
    {
        displayName: "Skid",
        key: "SKID",
        value: ShipmentType.SKID
    },
    {
        displayName: "Pallet",
        key: "PALLET",
        value: ShipmentType.PALLET
    },
    {
        displayName: "Tube",
        key: "TUBE",
        value: ShipmentType.TUBE
    },
    {
        displayName: "Crate",
        key: "CRATE",
        value: ShipmentType.CRATE
    },
    {
        displayName: "Satchel",
        key: "SATCHEL",
        value: ShipmentType.SATCHEL
    },
    {
        displayName: "Bag",
        key: "BAG",
        value: ShipmentType.BAG
    },
    {
        displayName: "Envelope",
        key: "ENVELOPE",
        value: ShipmentType.ENVELOPE
    },
    {
        displayName: "Other/Misc",
        key: "OTHER",
        value: ShipmentType.OTHER
    },
    {
        displayName: "Unpackaged",
        key: "UNPACKAGED",
        value: ShipmentType.UNPACKAGED
    },
    {
        displayName: "A-Trailer",
        key: "A_TRAILER",
        value: ShipmentType.A_TRAILER
    },
    {
        displayName: "B-Trailer",
        key: "B_TRAILER",
        value: ShipmentType.B_TRAILER
    },
    {
        displayName: "B-Double",
        key: "B_DOUBLE",
        value: ShipmentType.B_DOUBLE
    },
    {
        displayName: "Drop Deck Trailer",
        key: "DROP_DECK_TRAILER",
        value: ShipmentType.DROP_DECK_TRAILER
    },
    {
        displayName: "Single Trailer",
        key: "SINGLE_TRAILER",
        value: ShipmentType.SINGLE_TRAILER
    },
    
]

export const isFullTruckload = (shipmentType: string) => {
    return shipmentType === ShipmentType.A_TRAILER || shipmentType === ShipmentType.B_TRAILER || shipmentType === ShipmentType.B_DOUBLE || shipmentType === ShipmentType.DROP_DECK_TRAILER || shipmentType === ShipmentType.SINGLE_TRAILER;
}

export const ShipmentTypeOptions = () => {
    let options: any[] = [];
    ShipmentTypeProperties.forEach((item: EnumProperties) => {
        options.push({ label: item.displayName, value: item.value });
    });
    return options;
}

export const ShipmentDisplayNameByValue = (value: string) => {
    let displayName = "";
    ShipmentTypeProperties.forEach((item: EnumProperties) => {
        if (item.value === value) {
            displayName = item.displayName;
        }
    });
    return displayName;
}

export const BuildingTypeOptions = () => {
    return [
        {
            label: "Residential",
            value: "Residential"
        },
        {
            label: "Commercial",
            value: "Commercial"
        }
    ]
}

