import React, { useState } from "react"
import { classNames, cn } from "../../../utils/classNames"
import ToggleButton from "../ToggleButton"
import Popup from "../../popup/Popup"

import Mail03 from "@untitled-ui/icons-react/build/esm/Mail03"
import HelpCircle from "@untitled-ui/icons-react/build/esm/HelpCircle"
import Tooltip from "../../ui/Tooltip"

export type HelpPopupProps = {
    title: string
    content: React.ReactNode
    footer?: boolean
}

interface InputLabelWrapperProps {
    label?: string
    showLabel?: boolean
    showToggleButton?: boolean
    toggleDisable?: (value: 0 | 1) => void
    showHelpData?: HelpPopupProps
}

const InputLabelWrapper = ({
    label,
    showLabel = true,
    showToggleButton = false,
    toggleDisable,
    showHelpData
}: InputLabelWrapperProps) => {
    const [isHelpPopupOpen, setIsHelpPopupOpen] = useState(false)

    if (!showLabel) {
        return <></>
    }

    return (
        <>
            <p
                className={cn(
                    !label ? "invisible" : "",
                    " text-text_label text-sm font-normal flex justify-between items-center truncate"
                )}
            >
                <Tooltip content={label}>
                {label || "label"}
                </Tooltip>
                {showToggleButton ? (
                    <div>
                    </div>
                ) : showHelpData ? (
                    <HelpCircle onClick={() => setIsHelpPopupOpen(prev => !prev)} className=" w-5 h-5 sm:w-6 sm:h-6 cursor-pointer text-dark_gray" />
                ) : (
                    <></>
                )}
            </p>

            {showHelpData ? (
                <Popup
                    isOpen={isHelpPopupOpen}
                    title={showHelpData.title}
                    message=""
                    type="info"
                    footer={showHelpData.footer}
                    onClosePopUp={() => setIsHelpPopupOpen(false)}
                    content={showHelpData.content}
                />
            ) : null}
        </>
    )
}

export default InputLabelWrapper
