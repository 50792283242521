import XClose from "@untitled-ui/icons-react/build/esm/XClose"
import { cn } from "../../utils/classNames"

type Props = {
    isOpen: boolean
    onClose?: () => void
    children: React.ReactNode
}

const PopupWrapper = ({onClose, isOpen, children}: Props) => {
    return (
        <div className={cn("fixed inset-0 z-50 opacity-0 pointer-events-none transition-opacity duration-300", isOpen && " pointer-events-auto opacity-100")}>
            <div
                className="absolute inset-0 bg-black opacity-60"
                onClick={onClose}
            ></div>
            <div
                className={cn(
                    "w-full absolute -bottom-10 opacity-0 rounded-sm transform bg-white p-4 transition-all duration-300",
                    isOpen && " bottom-0 opacity-100"
                )}
            >
                {onClose ? (<div className="absolute top-4 right-4 rounded-full p-1 grid place-content-center bg-black/50 cursor-pointer">
                    <XClose
                        onClick={onClose}
                        className="text-white w-4 h-4"
                    />
                </div>) : null}
                {children}
            </div>
        </div>
    )
}

export default PopupWrapper
