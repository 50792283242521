import React from 'react';
import './App.css';
import QuotePage from './pages/quote/QuotePage';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/widget/:widgetId" element={<QuotePage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
