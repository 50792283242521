import { Commodity } from "./Commodity";
import { RateItem, defaultRateItem } from "./RateItem";
import { Receiver, defaultReceiver } from "./Receiver";
import { Sender, defaultSender } from "./Sender";

export interface TestRateCardDTO{
    goods : RateItem[];
    shipper : Sender;
    receiver : Receiver;
    emailAddress : string;
    widgetId : string;
    surcharges: string[]
}

export const defaultTestRateCardDTO : TestRateCardDTO = {
    goods : [defaultRateItem],
    shipper : defaultSender,
    receiver : defaultReceiver,
    widgetId : "",
    emailAddress : "",
    surcharges: []
}