import React, { useEffect } from "react"
import CollapsableContainer from "../../collapsableContainer/CollapsableContainer"
import Trash01 from "@untitled-ui/icons-react/build/esm/Trash01"
import Button from "../../button/Button"
import Dropdown from "../../input/Dropdown"
import { Control, UseFieldArrayRemove, UseFormGetValues, UseFormSetValue, useFieldArray } from "react-hook-form"
import { DropdownOption } from "../../../models/DropdownOption"
import {
    ShipmentTypeOptions,
    ShipmentDisplayNameByValue,
    ShipmentType,
    isFullTruckload
} from "../../../enum/ShipmentType"
import { RateItem } from "../../../models/RateItem"
import { TestRateCardDTO } from "../../../models/TestRateCardDTO"
import IncrementalNumber from "../../input/IncrementalNumber"
import ThreeDimensionInput from "../../input/ThreeDimensionInput"
import { MAX_WEIGHT } from "../../../constants/Common"
import Package from "@untitled-ui/icons-react/build/esm/Package"
import Copy06 from "@untitled-ui/icons-react/build/esm/Copy06"
import Input from "../../input/CustomInput"
import { YesNoOptions , YesNoByValue } from "../../../enum/YesNoEnum"
import { DimensionalUnits, DimensionalUnitsKeyByValue } from "../../../enum/DimensionalUnits"
import { WeightUnits, WeightUnitsKeyByValue } from "../../../enum/WeightUnits"

interface ItemProps {
    item?: RateItem
    control: Control<TestRateCardDTO>
    setValue: UseFormSetValue<TestRateCardDTO>
    getValues: UseFormGetValues<TestRateCardDTO>
    register: any
    unregister: any
    errors: any
    index: number
    remove: (index: number) => void
    append: (item: RateItem) => void
    dimentionalUnit: DimensionalUnits
    weightUnit: WeightUnits
}

const ItemForm: React.FC<ItemProps> = (props) => {
    const control = props.control
    const [shipmentTypeOption, setShipmentTypeOption] = React.useState<DropdownOption>({
        value: props.item?.goodsType || "",
        label: ShipmentDisplayNameByValue(props.item?.goodsType || "")
    })

    const [dgTypeOption, setDgTypeOption] = React.useState<DropdownOption>({
        value: props.getValues(`goods.${props.index}.commodityExists`),
        label: YesNoByValue(props.getValues(`goods.${props.index}.commodityExists`))
    })

    const [length, setLength] = React.useState<number | undefined>(props.getValues(`goods.${props.index}.goodsLength`))
    const [width, setWidth] = React.useState<number | undefined>(props.getValues(`goods.${props.index}.goodsWidth`))
    const [height, setHeight] = React.useState<number | undefined>(props.getValues(`goods.${props.index}.goodsHeight`))

    const dimentionUnit = DimensionalUnitsKeyByValue(props.dimentionalUnit)
    const weightUnit = WeightUnitsKeyByValue(props.weightUnit)

    const handleLengthChange = (value: number) => {
        setLength(value)
        props.setValue(`goods.${props.index}.goodsLength`, value)
    }

    const handleWidthChange = (value: number) => {
        setWidth(value)
        props.setValue(`goods.${props.index}.goodsWidth`, value)
    }

    const handleHeightChange = (value: number) => {
        setHeight(value)
        props.setValue(`goods.${props.index}.goodsHeight`, value)
    }

    const handleShipmentTypeChange = (shipmentTypeOption: DropdownOption) => {
        if (isFullTruckload(shipmentTypeOption.value)) {
            handleLengthChange(0)
            handleWidthChange(0)
            handleHeightChange(0)
        }
        setShipmentTypeOption(shipmentTypeOption)
        props.setValue(`goods.${props.index}.goodsType`, shipmentTypeOption.value)
    }

    const handleValueOptionChange = (option: DropdownOption) => {
        setDgTypeOption(option)
        props.setValue(`goods.${props.index}.commodityExists`, option.value)
    }

    const {
        fields: commodityList,
        append: commodityAppend,
        remove: commodityRemove
    } = useFieldArray({
        control,
        name: `goods.${props.index}.commodityList`
    })

    const validateValueLWH = (value: number) => {
        return isFullTruckload(shipmentTypeOption.value) || validateValue(value)
    }

    const validateValue = (value: number) => {
        return value > 0
    }

    const isLWHError = (index: number) => {
        return (
            props.errors.goods &&
            props.errors.goods[index] &&
            (props.errors.goods[index].goodsLength ||
                props.errors.goods[index].goodsWidth ||
                props.errors.goods[index].goodsHeight)
        )
    }

    const hasAnyError = () => {
        return (
            props.errors.goods &&
            props.errors.goods[props.index] &&
            (props.errors.goods[props.index].goodsQuantity ||
                props.errors.goods[props.index].goodsItemWeight ||
                isLWHError(props.index))
        )
    }

    const copyItem = () => {
        let item = props.getValues(`goods.${props.index}`)
        props.append(item)
    }

    const getDGHelpDataContent = () => (
        <div className="mt-4">
            <h4 className=" text-lg font-normal text-text_label">
                HAZMAT are materials posing transport risks, needing strict handling.
            </h4>
            <ol className=" mt-2 px-4 list-decimal font-normal text-text_label">
                <li>These goods often require forklifts, palletization, and sometimes tail-lift trucks for safe loading and unloading.</li>
                <li>
                    Dangerous goods (HAZMAT) are materials that pose risks to health, safety, or the environment during transport and are subject to strict handling regulations.
                </li>
                <li>May incur extra charges for specialized equipment and procedures.</li>
            </ol>
        </div>
    )


    return (
        <>
            <div className="grid grid-cols-2 gap-3 md:gap-4">
                <Dropdown
                    label={`Item ${props.index + 1}`}
                    handleChange={(option) => {
                        handleShipmentTypeChange(option)
                    }}
                    value={shipmentTypeOption}
                    options={ShipmentTypeOptions()}
                />
                <div className=" grid grid-cols-2 gap-3 md:gap-4">
                    <Input
                        placeholder="eg: 1"
                        label="Quantity"
                        maxLength={3}
                        type="number"
                        value={props.getValues(`goods.${props.index}.goodsQuantity`)}
                        handleChange={(value) => props.setValue(`goods.${props.index}.goodsQuantity`, parseInt(value))}
                        {...props.register(`goods.${props.index}.goodsQuantity`, { validate: validateValue })}
                        error={
                            props.errors.goods &&
                            props.errors.goods[props.index] &&
                            props.errors.goods[props.index].goodsQuantity
                                ? "Field is mandatory"
                                : ""
                        }
                    />

                    <Dropdown
                    showHelpData={{
                        title: "Dangerous Goods (HAZMAT)",
                        content: getDGHelpDataContent()
                    }}
                        label="DG/HAZMAT"
                        options={YesNoOptions()}
                        value={dgTypeOption}
                        handleChange={handleValueOptionChange}
                        {...props.register(`goods.${props.index}.commodityExists`)}
                    />
                </div>
                <div className=" col-span-2 grid grid-cols-4 gap-3 md:gap-4">
                    <Input
                        placeholder={`In ${weightUnit}`}
                        label={`Weight(${weightUnit})`}
                        type="number"
                        value={props.getValues(`goods.${props.index}.goodsItemWeight`)}
                        handleChange={(value) => props.setValue(`goods.${props.index}.goodsItemWeight`, parseInt(value))}
                        {...props.register(`goods.${props.index}.goodsItemWeight`, { validate: validateValue })}
                        canDecimals={true}
                        max={MAX_WEIGHT}
                        error={
                            props.errors.goods &&
                            props.errors.goods[props.index] &&
                            props.errors.goods[props.index].goodsItemWeight
                                ? "Field is mandatory"
                                : ""
                        }
                    />
                    <Input
                        placeholder={`In ${dimentionUnit}`}
                        label={`Length(${dimentionUnit})`}
                        type="number"
                        value={props.getValues(`goods.${props.index}.goodsLength`)}
                        handleChange={(value) => props.setValue(`goods.${props.index}.goodsLength`, parseInt(value))}
                        {...props.register(`goods.${props.index}.goodsLength`, { validate: validateValueLWH })}
                        canDecimals={true}
                        error={
                            props.errors.goods &&
                            props.errors.goods[props.index] &&
                            props.errors.goods[props.index].goodsLength
                                ? "Field is mandatory"
                                : ""
                        }
                    />
                    <Input
                            placeholder={`In ${dimentionUnit}`}
                            label={`Width(${dimentionUnit})`}
                            type="number"
                            value={props.getValues(`goods.${props.index}.goodsWidth`)}
                            handleChange={(value) => props.setValue(`goods.${props.index}.goodsWidth`, parseInt(value))}
                            {...props.register(`goods.${props.index}.goodsWidth`, { validate: validateValueLWH })}
                            canDecimals={true}
                            error={
                                props.errors.goods &&
                                props.errors.goods[props.index] &&
                                props.errors.goods[props.index].goodsWidth
                                    ? "Field is mandatory"
                                    : ""
                            }
                        />
                    <Input
                        placeholder={`In ${dimentionUnit}`}
                        label={`Height(${dimentionUnit})`}
                        type="number"
                        value={props.getValues(`goods.${props.index}.goodsHeight`)}
                        handleChange={(value) => props.setValue(`goods.${props.index}.goodsHeight`, parseInt(value))}
                        {...props.register(`goods.${props.index}.goodsHeight`, { validate: validateValueLWH })}
                        canDecimals={true}
                        error={
                            props.errors.goods &&
                            props.errors.goods[props.index] &&
                            props.errors.goods[props.index].goodsHeight
                                ? "Field is mandatory"
                                : ""
                        }
                    />
    
                </div>
            </div>
            {props.getValues("goods").length > 1 ? (
                <div className="mt-4 flex justify-end">
                    <Button
                        buttonType={5}
                        type="button"
                        label="Delete Item"
                        icon={<Trash01 className="w-6 h-6" />}
                        onClick={() => props.remove(props.index)}
                    />
                </div>
            ) : null}
        </>
    )
}

export default ItemForm
