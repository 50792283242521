import Loading01 from "@untitled-ui/icons-react/build/esm/Loading01";
import React from "react";
import { SpinnerCircularFixed } from 'spinners-react';

interface ButtonLoaderProps {
  buttonType : number
}

const ButtonLoader:React.FC<ButtonLoaderProps> = ({buttonType}) => {

  interface ColorPalette {
    primary: string;
    secondary: string;
  }
  
  const colorPalette: Record<number, ColorPalette> = {
    1: {
      primary: "rgb(255 255 255 / var(--tw-text-opacity))", // white
      secondary: "rgba(255,255,255,0.44)", // white with opacity
    },
    2: {
      primary: "rgb(16 24 40 / var(--tw-text-opacity))",
      secondary: "rgba(16,24,40,0.30)",
    },
    3: {
      primary: "rgb(0 78 235 / var(--tw-text-opacity))",
      secondary: "rgba(16,24,40,0.30)",
    },
    4: {
      primary: "rgb(255 255 255 / var(--tw-text-opacity))", // white
      secondary: "rgba(255,255,255,0.44)",
    },
    5: {
      primary: "rgb(217 45 32 / var(--tw-text-opacity))",
      secondary: "rgba(16,24,40,0.30)",
    },
    6: {
      primary: "rgb(0 78 235 / var(--tw-text-opacity))",
      secondary: "rgba(16,24,40,0.30)",
    },
    7: {
      primary: "rgb(152 162 179 / var(--tw-text-opacity))",
      secondary: "rgba(16,24,40,0.30)",
    },
    8: {
      primary: "rgb(180 35 24 / var(--tw-text-opacity))",
      secondary: "rgba(16,24,40,0.30)",
    },
  };
  
  const getPrimaryColor = (buttonType: number): string => colorPalette[buttonType]?.primary ?? "rgb(16 24 40 / var(--tw-text-opacity))";
  const getSecondaryColor = (buttonType: number): string => colorPalette[buttonType]?.secondary ?? "rgba(16,24,40,0.30)";
  

  return (
    <SpinnerCircularFixed size={25} thickness={200} speed={100} color={getPrimaryColor(buttonType)} secondaryColor={getSecondaryColor(buttonType)} />
  );
};

export default ButtonLoader;
