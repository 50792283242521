import { BuildingType } from "../enum/BuildingType";
import { MasterPostCode } from "./MasterPostCode";

export interface Receiver {
  receiverPostalCode: string;
  receiverIdentityPostcode: string;
  receiverAreaCode: string;
  receiverAreaName: string;
  receiverCity: string;
  receiverState: string;
  receiverRegionName: string
  receiverRegionCode: string
  receiverCountryCode: string;
  receiverCompanyName: string;
  receiverContactName: string;
  receiverBuildingType: BuildingType;
  receiverPhone: string;
  receiverEmail: string;
  receiverStreet1: string;
  receiverStreet2: string;
  receiverStreet3: string;
  receiverInstructions: string;
  receiverOpens: string;
  receiverCloses: string;
  receiverLocationType: string;
  receiverRequiresTailLift: boolean;
  receiverGivesAuthorityToLeave: boolean;

  receiverStateName: string;
  receiverCityName: string;
  receiverSuburbName: string;

  receiverLat:string;
  receiverLong:string;
}

export const defaultReceiver: Receiver = {
    receiverPostalCode: "",
    receiverIdentityPostcode: "",
    receiverAreaCode: "",
    receiverAreaName: "",
    receiverCity: "",
    receiverState: "",
    receiverRegionName: "",
    receiverRegionCode: "",
    receiverCountryCode: "AU",
    receiverCompanyName: "",
    receiverContactName: "",
    receiverBuildingType: BuildingType.COMMERCIAL,
    receiverPhone: "",
    receiverEmail: "",
    receiverStreet1: "",
    receiverStreet2: "",
    receiverStreet3: "",
    receiverInstructions: "",
    receiverOpens: "",
    receiverCloses: "",
    receiverLocationType: "",
    receiverRequiresTailLift: false,
    receiverGivesAuthorityToLeave: false,

    receiverStateName: "",
    receiverCityName: "",
    receiverSuburbName: "",

    receiverLat:"",
    receiverLong:""
    };

export function getReceiverByMasterCode(masterCode: MasterPostCode) : Receiver {
    return {
        receiverPostalCode: masterCode.postcode,
        receiverIdentityPostcode: masterCode.identityPostcode,
        receiverAreaCode: masterCode.areaCode,
        receiverAreaName: masterCode.areaName,
        receiverCity: masterCode.cityCode,
        receiverState: masterCode.stateCode,
        receiverRegionName: masterCode.region,
        receiverRegionCode: masterCode.regionCode,
        receiverCountryCode: masterCode.country,
        receiverCompanyName: "",
        receiverContactName: "",
        receiverBuildingType: BuildingType.COMMERCIAL,
        receiverPhone: "",
        receiverEmail: "",
        receiverStreet1: masterCode.identityPostcode,
        receiverStreet2: "",
        receiverStreet3: "",
        receiverInstructions: "",
        receiverOpens: "",
        receiverCloses: "",
        receiverLocationType: "",
        receiverRequiresTailLift: false,
        receiverGivesAuthorityToLeave: false,
        receiverStateName: masterCode.stateName,
        receiverCityName: masterCode.cityName,
        receiverSuburbName: masterCode.suburbName,
        receiverLat: masterCode.latGoogle,
        receiverLong: masterCode.longGoogle
    }
}
