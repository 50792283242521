import { useState, useRef, useEffect } from "react";
import { classNames, cn } from "../../utils/classNames";
import ChevronDown from "@untitled-ui/icons-react/build/esm/ChevronDown";
import Check from "@untitled-ui/icons-react/build/esm/Check";
import { DropdownOption } from "../../models/DropdownOption";
import SearchLg from "@untitled-ui/icons-react/build/esm/SearchLg";
import InputWrapper from "./wrapper/InputWrapper";
import { ReactCountryFlag } from 'react-country-flag'
import { getISOAlpha2Code } from "../../enum/ZoneCountry";
import { HelpPopupProps } from "./wrapper/InputLabelWrapper";

interface DropdownProps {
  label?: string;
  handleChange: (e: any) => void;
  value: DropdownOption;
  options: DropdownOption[];
  searchPlaceholder?: string;
  error?: string;
  subtitle?: string;
  emptyMessage?: string;
  showLabel?: boolean;
  disabled?: boolean;
  showErrorMessage?: boolean;
  sortByLabel?: boolean;
  countryDropdown?: boolean
  containerClassName?: string
  showHelpData?: HelpPopupProps
}
const Dropdown = ({
  label,
  handleChange,
  value,
  options,
  error,
  searchPlaceholder,
  subtitle,
  emptyMessage,
  showLabel = true,
  disabled = false,
  showErrorMessage = true,
  sortByLabel = false,
  countryDropdown = false,
  containerClassName,
  showHelpData

}: DropdownProps) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const inputRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openDropdown]);

  const sortOptions = (options: DropdownOption[]) => {
    if (sortByLabel) {
      return options?.sort((a, b) => a.label.localeCompare(b.label));
    }
    return options;
  };

  const [stateOptions, setStateOptions] = useState<DropdownOption[]>(sortOptions(options));
  const filteredValues = (inputValue: string) => {
    return stateOptions?.filter((option) => {
      return option.label.toLowerCase().includes(inputValue.toLowerCase());
    });
  };

  useEffect(() => {
    if (!openDropdown) {
      setSearchValue("");
    }
  }, [openDropdown]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && openDropdown) {
        setOpenDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };

  }, [openDropdown]);

  useEffect(() => {
    setStateOptions(sortOptions(options));
  }, [options]);

  useEffect(() => {
    const handleKeyDown = (e: any) => {
      if (openDropdown) {
        if (e.key === "ArrowDown") {
          const index = stateOptions.findIndex((option) => option.value === value.value);
          if (index < stateOptions.length - 1) {
            handleChange(stateOptions[index + 1]);
          }
        }
        if (e.key === "ArrowUp") {
          const index = stateOptions.findIndex((option) => option.value === value.value);
          if (index > 0) {
            handleChange(stateOptions[index - 1]);
          }
        }
        if (e.key === "Enter") {
          setOpenDropdown(!openDropdown);
        }
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [openDropdown, value]);

  return (
      <InputWrapper
          ref={dropdownRef}
          label={label}
          showLabel={showLabel}
          subtitle={subtitle}
          error={error}
          showErrorMessage={showErrorMessage}
          showHelpData={showHelpData}
      >
          <div
              onClick={() => {
                  if (disabled) return
                  setOpenDropdown(!openDropdown)
              }}
              tabIndex={0}
              onKeyPress={(e) => {
                  if (e.key === "Enter" || e.key === " " || e.key === "Tab") {
                      setOpenDropdown(!openDropdown)
                  }
              }}
              className={cn(
                  error && "border-primary_error",
                  disabled && "cursor-not-allowed",
                  !disabled
                      ? error
                          ? "focus:border-primary_error focus:outline-primary_error"
                          : "focus:border-primary_color focus:outline-primary_color"
                      : "",
                  "flex items-center justify-between py-3 px-3 border bg-white rounded-lg border-border_primary cursor-pointer", containerClassName
              )}
          >
              {value.icon && value.icon}
                {!countryDropdown ? (
                    <p
                        className={classNames(
                            "font-normal text-base truncate",
                            disabled ? "text-text_tertiary_color" : "text-text_primary_color"
                        )}
                    >
                        {value.label}
                        {!value.label && (
                            <span className="opacity-50 text-text_secondary_color">{searchPlaceholder}</span>
                        )}
                    </p>
                ) : (
                    <div className=" flex gap-1 items-center">
                        <ReactCountryFlag
                            countryCode={getISOAlpha2Code(value.value)}
                            svg
                            style={{
                                fontSize: "1.2rem"
                            }}
                        />
                        <p className="font-normal text-base text-text_primary_color">{value.value}</p>
                    </div>
                )}
              <div>
                  <ChevronDown className="w-5 h-5 text-light_gray" />
              </div>
          </div>
          {openDropdown && (
              <div className="absolute left-0 w-full max-h-64 overflow-auto bg-white rounded-lg border border-border_primary z-10">
                  {stateOptions.length > 3 && (
                      <div className="flex items-center gap-4 border-b border-border_secondary p-2">
                          {!countryDropdown ? <SearchLg className="w-5 h-5 text-blue_1" /> : null}
                          <input
                              ref={inputRef}
                              type="text"
                              placeholder={searchPlaceholder || "Search"}
                              className="w-full outline-none border-none focus:outline-none focus:border-none"
                              value={searchValue}
                              onChange={(e) => {
                                  setSearchValue(e.target.value)
                              }}
                          />
                      </div>
                  )}
                  {stateOptions.length === 0 && emptyMessage && (
                      <div
                          key={"empty"}
                          className={"py-2 px-2.5 hover:bg-background_primary flex items-center justify-between"}
                      >
                          <p className="font-normal text-base italic text-text_secondary_color">{emptyMessage}</p>
                      </div>
                  )}
                  {filteredValues(searchValue).map((option) => (
                      <div
                          key={option.value}
                          onClick={() => {
                              handleChange(option)
                              setOpenDropdown(false)
                          }}
                          className={classNames(
                              option.value === value.value ? "bg-background_primary" : "",
                              "py-2 px-2.5 hover:bg-background_primary cursor-pointer flex items-center justify-between"
                          )}
                      >
                          {option.icon && option.icon}
                          {!countryDropdown ? (
                                <>
                                    <p className="font-normal text-base text-text_primary_color">{option.label}</p>
                                    {option.value === value.value && <Check className="w-4 h-4 text-primary_color" />}
                                </>
                            ) : (
                                <div className=" flex gap-1 items-center">
                                    <ReactCountryFlag
                                        countryCode={getISOAlpha2Code(option.value)}
                                        svg
                                        style={{
                                            fontSize: "1.2rem"
                                        }}
                                    />
                                    <p className="font-normal text-base text-text_primary_color">{option.value}</p>
                                </div>
                            )}
                      </div>
                  ))}
              </div>
          )}
      </InputWrapper>
  )
};

export default Dropdown;
