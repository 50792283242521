import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../Store"

type ErrorPopupState = {
    isOpen: boolean
    onClose: () => void
}

const initialState: ErrorPopupState = {
    isOpen: false,
    onClose: () => {}
}

export const ErrorPopupSlice = createSlice({
    name: "ErrorPopup",
    initialState,
    reducers: {
        
        openErrorPopup: (state, action: PayloadAction<{isOpen: boolean}>) => {
            const {isOpen} = action.payload
            state.isOpen = isOpen
        },
        setOnErrorPopupClose: (state, action: PayloadAction<{onClose: () => void}>) => {
            const {onClose} = action.payload
            state.onClose = onClose
        }

    }
})

export const { openErrorPopup, setOnErrorPopupClose } = ErrorPopupSlice.actions

export const getIsOpen = (state: RootState) => state.errorPopup.isOpen
export const getOnClose = (state: RootState) => state.errorPopup.onClose

export default ErrorPopupSlice.reducer
