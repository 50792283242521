import { Link } from "react-router-dom"
import Button from "../button/Button"
import PopupWrapper from "./PopupWrapper"

import CheckCircle from "@untitled-ui/icons-react/build/esm/CheckCircle"

type Props = {
    isOpen: boolean
    onClose: () => void
    title: string
    desc: string
    buttonList? : React.ReactNode[]
}

const SuccessPopup = ({isOpen, onClose, title, desc, buttonList }: Props) => {
    return (
        <PopupWrapper
            isOpen={isOpen}
            onClose={onClose}
        >
            <img
                src="/static/emailIcon.png"
                className=" w-52 aspect-square text-primary_color mx-auto"
            />
            <h1 className=" mt-4 text-lg font-semibold text-center text-text_primary_color">{title}</h1>
            <p className=" text-base text-center font-normal text-text_secondary_color">{desc}</p>
            <div className="mt-8">
            {buttonList}
            </div>
        </PopupWrapper>
    )
}

export default SuccessPopup
