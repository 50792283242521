import { useState, useEffect } from "react";
import InputWrapper from "./wrapper/InputWrapper";
import { cn } from "../../utils/classNames";

interface CheckboxWithLabelProps {
  label: React.ReactNode;
  checked: boolean;
  handleChange: (value: boolean) => void;
  error?: string;
  subtitle?: string;
  showLabel?: boolean;
}

const CheckboxWithLabel = ({ error, checked, handleChange, label, subtitle, showLabel = false }: CheckboxWithLabelProps) => {

  const handleCheckboxChange = (value: boolean) => {
    // setCurrentValue(value);
    handleChange(value);
  }
  return (
    <InputWrapper error={error} subtitle={subtitle} showLabel={showLabel}>
      <div className="flex gap-2 items-center">
        <input
          title="checkbox"
          type="checkbox"
          checked={checked}
          className={cn("w-4 h-4 rounded-sm accent-primary_color")}
          onChange={(e) => {
            handleCheckboxChange(e.target.checked);
          }}
        />
        <p className={cn("text-text_tertiary_color text-sm font-medium", error && "text-primary_error")}>{label}</p>
      </div>
    </InputWrapper>
  );
};

export default CheckboxWithLabel;
