import React, { useEffect, useRef, useState } from "react"
import ReCAPTCHA from "react-google-recaptcha"

import { TestRateCardDTO, defaultTestRateCardDTO } from "../../../models/TestRateCardDTO"
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form"
import { RateItem, defaultRateItem } from "../../../models/RateItem"
import DynamicDropdown from "../../input/DynamicDropdown"
import { MasterPostCode, getDisplayValueByFieldType } from "../../../models/MasterPostCode"
import { useGetPostCodesQuery, useGetQuoteMutation, useGetUserInputSurchargeRulesQuery } from "../../../api/WidgetQuoteAPI"
import { BuildingType } from "../../../enum/BuildingType"
import Button from "../../button/Button"
import ItemForm from "./ItemForm"
import Plus from "@untitled-ui/icons-react/build/esm/Plus"
import Input from "../../input/CustomInput"
import { validateEmail } from "../../../utils/CommonUtil"
import Popup from "../../popup/Popup"
import { useParams } from "react-router-dom"
import SuccessPopup from "../../popup/SuccessPopup"
import ToggleButton from "../../input/ToggleButton"
import Separator from "../../ui/separator"
import PoweredBy from "../../PoweredBy"
import CheckboxWithLabel from "../../input/CheckboxWithLabel"

// --links--
import { PRIVACY_POLICY, TERMS_CONDITIONS } from "../../../constants/Links"
import { useDispatch } from "react-redux"
import { openErrorPopup, setOnErrorPopupClose } from "../../../store/features/ErrorPopupSlice"
import { QuoteWrapperProps } from "../QuoteWrapper"
import PostcodeDynamicDD from "../../common/PostcodeDynamicDD"
import AlertBox from "../../alertBox/AlertBox"
import PillToggleList from "./PillToggleList"
const siteKey = "6LcS5qwpAAAAAH_kOO0kZHUdHe-AhMckqVKPa_Ky"

const GetQuoteForm: React.FC<QuoteWrapperProps> = (props: QuoteWrapperProps) => {
    const dispatch = useDispatch()
    const {
        control,
        register,
        unregister,
        handleSubmit,
        getValues,
        setValue,
        reset,
        watch,
        formState: { errors }
    } = useForm<TestRateCardDTO>({ defaultValues: defaultTestRateCardDTO })
    const [originDisplayValue, setOriginDiplayValue] = useState<string>("")
    const [receiverDisplayValue, setReceiverDiplayValue] = useState<string>("")
    const [invalidGoodsList, setInvalidGoodsList] = React.useState<boolean>(false)
    const recaptchaRef = useRef<ReCAPTCHA>(null)

    const [isResidentialPickup, setIsResidentialPickup] = useState<boolean>(
        defaultTestRateCardDTO.shipper.shipperBuildingType === BuildingType.RESIDENTIAL
    )
    const [isResidentialDelivery, setIsResidentialDelivery] = useState<boolean>(
        defaultTestRateCardDTO.receiver.receiverBuildingType === BuildingType.RESIDENTIAL
    )
    const [isOriginTailLiftRequired, setIsOriginTailLiftRequired] = useState<boolean>(
        defaultTestRateCardDTO.shipper.shipperRequiresTailLift
    )
    const [isReceiverTailLiftRequired, setIsReceiverTailLiftRequired] = useState<boolean>(
        defaultTestRateCardDTO.receiver.receiverRequiresTailLift
    )
    const useQueryHook = (search: string, fieldName: string) => {
        const {
            data: postCodes,
            error,
            isLoading,
            isFetching,
        } = useGetPostCodesQuery({ query: search, fieldName: fieldName })
        return { data: postCodes, isLoading, isFetching, error }
    }

    const {
        data: userInputSurcharges,
        isFetching : isUserInputSurchargesFetching,
    } = useGetUserInputSurchargeRulesQuery({ shipperId: props.data?.shipperId ? props.data?.shipperId : "" });

    const [trigger, { isLoading, isSuccess, isError, reset: resetGetQuoteMutation }] = useGetQuoteMutation()
    const [formData, setFormData] = useState<TestRateCardDTO>(defaultTestRateCardDTO)

    const [email, setEmail] = useState<string>("")
    const [emailError, setEmailError] = useState(false)
    const [acceptTermsError, setAcceptTermsError] = useState<boolean>(false)
    const [showEmailInput, setShowEmailInput] = useState<boolean>(false)
    const [acceptTAndC, setAcceptTAndC] = useState<boolean>(false)
    const { widgetId } = useParams<{ widgetId: string }>()

    const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useState(false)

    const resetForm = () => {
        // console.log(defaultRateItem)
        reset(defaultTestRateCardDTO)
        setEmail("")
        setOriginDiplayValue("")
        setReceiverDiplayValue("")
        setIsOriginTailLiftRequired(defaultTestRateCardDTO.shipper.shipperRequiresTailLift)
        setIsReceiverTailLiftRequired(defaultTestRateCardDTO.receiver.receiverRequiresTailLift)
        setIsResidentialPickup(defaultTestRateCardDTO.shipper.shipperBuildingType === BuildingType.RESIDENTIAL)
        setIsResidentialDelivery(defaultTestRateCardDTO.receiver.receiverBuildingType === BuildingType.RESIDENTIAL)
        setShowEmailInput(false)
        setAcceptTAndC(false)
        dispatch(openErrorPopup({ isOpen: false }))

    }

    useEffect(() => {
        if (isError) {
            dispatch(openErrorPopup({ isOpen: true }))
            dispatch(setOnErrorPopupClose({ onClose: resetForm }))
            resetGetQuoteMutation()
        }
    }, [isError])

    useEffect(() => {
        if (isSuccess) {
            setShowEmailInput(false)
            setIsSuccessPopupOpen(true)
            resetForm()
        }
    }, [isSuccess])

    const useSuburbHook = (search: string) => {
        return useQueryHook(search, "identityPostcode")
    }

    const handleGetQuote = () => {
        if (!validateEmail(email)) {
            setEmailError(true)
            return false
        } else {
            setEmailError(false)
        }
        if (!acceptTAndC) {
            setAcceptTermsError(true)
            return false
        } else {
            setAcceptTermsError(false)
        }
        if (widgetId) {
            let updatedData: TestRateCardDTO = { ...formData, emailAddress: email, widgetId: widgetId }
            trigger(updatedData)
        }
        return true
    }

    const handleSave: SubmitHandler<TestRateCardDTO> = async (data) => {
        try {
            const token = await recaptchaRef.current?.executeAsync()
            recaptchaRef.current?.reset() // Reset reCAPTCHA

            const dataWithToken = {
                ...data,
                token
            }
            setFormData(dataWithToken)
            setShowEmailInput(true)
        } catch (error) {
            dispatch(openErrorPopup({ isOpen: true }), setOnErrorPopupClose({ onClose: resetForm }))
            console.log(error)
        }
    }

    const { fields, append, remove } = useFieldArray({
        control,
        name: "goods"
    })

    const handleRemoveItem = (index: number) => {
        remove(index)
    }

    const appendItem = (item: RateItem) => {
        setInvalidGoodsList(false)
        append(item)
    }
    const handleOriginChange = (postcode: MasterPostCode) => {
        setValue("shipper.shipperPostalCode", postcode.postcode)
        setValue("shipper.shipperIdentityPostcode", postcode.identityPostcode)
        setValue("shipper.shipperStreet1", postcode.identityPostcode)
        setValue("shipper.shipperAreaCode", postcode.areaCode)
        setValue("shipper.shipperCity", postcode.cityCode)
        setValue("shipper.shipperState", postcode.stateCode)
        setValue("shipper.shipperAreaName", postcode.areaName)
        setValue("shipper.shipperCountryCode", postcode.country)
        setValue("shipper.shipperSuburbName", postcode.suburbName)
        setValue("shipper.shipperCityName", postcode.cityName)
        setValue("shipper.shipperStateName", postcode.stateName)
        setValue("shipper.shipperRegionName", postcode.region)
        setValue("shipper.shipperRegionCode", postcode.regionCode)
        setValue("shipper.shipperLat", postcode.latGoogle)
        setValue("shipper.shipperLong", postcode.longGoogle)

        setOriginDiplayValue(
            getDisplayValueByFieldType(postcode, "identityPostcode")
        )
    }

    const handleReciverChange = (postcode: MasterPostCode) => {
        setValue("receiver.receiverPostalCode", postcode.postcode)
        setValue("receiver.receiverStreet1", postcode.identityPostcode)
        setValue("receiver.receiverIdentityPostcode", postcode.identityPostcode)
        setValue("receiver.receiverAreaCode", postcode.areaCode)
        setValue("receiver.receiverCity", postcode.cityCode)
        setValue("receiver.receiverState", postcode.stateCode)
        setValue("receiver.receiverAreaName", postcode.areaName)
        setValue("receiver.receiverCountryCode", postcode.country)
        setValue("receiver.receiverSuburbName", postcode.suburbName)
        setValue("receiver.receiverCityName", postcode.cityName)
        setValue("receiver.receiverStateName", postcode.stateName)
        setValue("receiver.receiverRegionName", postcode.region)
        setValue("receiver.receiverRegionCode", postcode.regionCode)
        setValue("receiver.receiverLat", postcode.latGoogle)
        setValue("receiver.receiverLong", postcode.longGoogle)

        setReceiverDiplayValue(
            getDisplayValueByFieldType(postcode, "identityPostcode")
        )
    }

    const handleShipperBuildingTypeChange = (value: boolean) => {
        setIsResidentialPickup(value)
        setValue("shipper.shipperBuildingType", value ? BuildingType.RESIDENTIAL : BuildingType.COMMERCIAL)
    }

    const handleReceiverBuildingTypeChange = (value: boolean) => {
        setIsResidentialDelivery(value)
        setValue("receiver.receiverBuildingType", value ? BuildingType.RESIDENTIAL : BuildingType.COMMERCIAL)
    }

    const handleOriginTailLiftChange = (value: boolean) => {
        setIsOriginTailLiftRequired(value)
        setValue("shipper.shipperRequiresTailLift", value)
    }

    const handleReceiverTailLiftChange = (value: boolean) => {
        setIsReceiverTailLiftRequired(value)
        setValue("receiver.receiverRequiresTailLift", value)
    }

    const getSendQuoteContent = () => {
        return (
            <div className="flex flex-col gap-3 mt-5 mb-5">
                <Input
                    label="Business Email"
                    value={email}
                    handleChange={handleResetEmailChange}
                    placeholder="Enter your Business Email"
                    type="email"
                    error={emailError ? "Enter valid email" : ""}
                />
                <CheckboxWithLabel
                    checked={acceptTAndC}
                    handleChange={() => setAcceptTAndC((prev) => !prev)}
                    label={
                        <>
                            I accept the{"  "}
                            <a
                                className=" underline"
                                href={props.data.settingJson?.uiSettings?.termsAndConditionsURL}
                                target="_blank">
                                terms and conditions
                            </a>
                        </>
                    }
                    error={acceptTermsError ? "You must accept terms & conditions" : ""}
                />
            </div>
        )
    }

    const getOriginHelpDataContent = () => (
        <div className="mt-4">
            <h4 className=" text-lg font-normal text-text_label">
                Origin is where the shipment is being picked up from.
            </h4>
            <ol className=" mt-2 px-4 list-decimal font-normal text-text_label">
                <li>By default it's considered as a commercial location that has a folk-lift on-site.</li>
                <li>
                    Items that are 25kgs or more need to be palletised and fork-lifted on/off the truck. If you don't
                    have a fork-lift on site at either origin or destination, please toggle tail-lift required so we can
                    send a truck with a tail-lift.
                </li>
                <li>Both tail-lift trucks and residential buildings may incur additional surcharges.</li>
            </ol>
        </div>
    )

    const getDestinationHelpDataContent = () => (
        <div className="mt-4">
            <h4 className=" text-lg font-normal text-text_label">
                Destination is where the shipment is being delivered to.
            </h4>
            <ol className=" mt-2 px-4 list-decimal font-normal text-text_label">
                <li>By default it's considered as a commercial location that has a folk-lift on-site.</li>
                <li>
                    Items that are 25kgs or more need to be palletised and fork-lifted on/off the truck. If you don't
                    have a fork-lift on site at either origin or destination, please toggle tail-lift required so we can
                    send a truck with a tail-lift.
                </li>
                <li>Both tail-lift trucks and residential buildings may incur additional surcharges.</li>
            </ol>
        </div>
    )

    const handleResetEmailChange = (value: string) => {
        setEmail(value)
        setEmailError(false)
    }

    const handleSurchargeToggle = (value: string[]) => {
        setValue("surcharges", value)
    }



    return (
        <form onSubmit={handleSubmit(handleSave)} className="w-full">
            <ReCAPTCHA
                sitekey={siteKey}
                size="invisible"
                ref={recaptchaRef}
                badge="bottomleft"
            />

            <div className="w-full">
                <PostcodeDynamicDD
                    countryCode={props.data.carrier.countryCode}
                    showHelpData={{
                        title: "Origin",
                        content: getOriginHelpDataContent()
                    }}
                    label="Origin"
                    value={originDisplayValue}
                    placeholder="Type suburb, city or postcode"
                    handleChange={handleOriginChange}
                    {...register("shipper.shipperPostalCode")}
                    error={errors.shipper?.shipperPostalCode ? "Origin is mandatory" : ""}
                    fieldName="identityPostcode"
                />
            </div>
            <div className="grid grid-cols-2 mt-4">
                <ToggleButton
                    label={"Residential Pickup"}
                    handleChange={handleShipperBuildingTypeChange}
                    checked={isResidentialPickup}
                />
                <ToggleButton
                    label={"Tail Lift Required"}
                    checked={isOriginTailLiftRequired}
                    handleChange={handleOriginTailLiftChange}
                />
            </div>

            <div className="mt-4 w-full">
                <PostcodeDynamicDD
                    countryCode={props.data.carrier.countryCode}
                    showHelpData={{
                        title: "Destination",
                        content: getDestinationHelpDataContent()
                    }}
                    label="Destination"
                    value={receiverDisplayValue}
                    placeholder="Type suburb, city or postcode"
                    handleChange={handleReciverChange}
                    {...register("receiver.receiverPostalCode")}
                    error={errors.receiver?.receiverPostalCode ? "Destination is mandatory" : ""}
                    fieldName="identityPostcode"
                />
            </div>
            <div className="grid grid-cols-2 gap-2 mt-4">
                <ToggleButton
                    label={"Residential Delivery"}
                    handleChange={handleReceiverBuildingTypeChange}
                    checked={isResidentialDelivery}
                />
                <ToggleButton
                    label={"Tail Lift Required"}
                    checked={isReceiverTailLiftRequired}
                    handleChange={handleReceiverTailLiftChange}
                />
            </div>

            { !isUserInputSurchargesFetching ? <div className="mt-4 w-full">
                <PillToggleList carrier={props.data.carrier} surcharges={userInputSurcharges} value={getValues("surcharges")} setValue={handleSurchargeToggle} />
            </div> : null}

            <Separator className=" my-4" />
            {invalidGoodsList && (
                <div className="pb-2">
                    <p className="font-medium text-primary_error text-sm mb-1">Atleast one item needs to be added</p>
                </div>
            )}
            {fields.map((item, index) => {
                return (
                    <>
                        <ItemForm
                            key={item.id}
                            index={index}
                            item={item}
                            getValues={getValues}
                            setValue={setValue}
                            remove={handleRemoveItem}
                            register={register}
                            unregister={unregister}
                            errors={errors}
                            control={control}
                            append={appendItem}
                            dimentionalUnit={props.data.carrier.dimensionUnit}
                            weightUnit={props.data.carrier.weightUnit}
                        />
                        <Separator className=" my-4" />
                    </>
                )
            })}
            {props.data.shipperId && <div className="grid grid-cols-2 gap-3 md:gap-4">
                <Button
                    buttonType={2}
                    type="button"
                    label="Add Item"
                    icon={<Plus className="w-6 h-6" />}
                    onClick={() => {
                        appendItem(defaultRateItem)
                    }}
                />
                <Button
                    type="submit"
                    buttonType={1}
                    label="Get Quote"
                />
            </div>}
            {
                !props.data.shipperId && <AlertBox type={"error"} title="Warning" message={'Account is not associated with this widget. Please link account to generate quotes'} />
            }

            <PoweredBy />

            <Popup
                isOpen={showEmailInput}
                title="Get Instant Quote"
                message=""
                type="info"
                onClosePopUp={() => {
                    setShowEmailInput(false)
                }}
                primaryButtonLabel="Get Instant Quote"
                primaryButtonAction={handleGetQuote}
                showPrimaryButtonLoader={isLoading}
                content={getSendQuoteContent()}
            />

            <SuccessPopup
                isOpen={isSuccessPopupOpen}
                onClose={() => setIsSuccessPopupOpen(false)}
                title="We've emailed you the the instant quote."
                desc="If you didn't get an email, please check your spam folder or request the quote again."
                buttonList={[
                    <Button
                        size="xl"
                        buttonType={1}
                        label="Get new quote"
                        onClick={() => setIsSuccessPopupOpen(false)}
                    />
                ]}
            />
        </form>
    )
}

export default GetQuoteForm
