import Title from "../title/Title"
import SubTitle from "../subTitle/SubTitle"
import XClose from "@untitled-ui/icons-react/build/esm/XClose"
import { classNames } from "../../utils/classNames"
import { useAppDispatch } from "../../store/Store"
import EyeOff from "@untitled-ui/icons-react/build/esm/EyeOff"
import CheckCircle from "@untitled-ui/icons-react/build/esm/CheckCircle"
import AlertCircle from "@untitled-ui/icons-react/build/esm/AlertCircle"
import Button from "../button/Button"
import PopupWrapper from "./PopupWrapper"

interface PopupProps {
    isOpen: boolean
    title: string
    message?: string
    type: "success" | "error" | "warning" | "info"
    icon?: React.ReactNode
    primaryButtonLabel?: string
    primaryButtonAction?: () => void
    secondaryButtonLabel?: string
    secondaryButtonAction?: () => void
    showPrimaryButtonLoader?: boolean
    content?: React.ReactNode
    onClosePopUp?: () => void
    footer?: boolean
}

const Popup = ({
    isOpen,
    title,
    icon,
    message = "",
    type,
    primaryButtonLabel,
    primaryButtonAction,
    showPrimaryButtonLoader = false,
    secondaryButtonLabel,
    secondaryButtonAction,
    content,
    onClosePopUp,
    footer = false
}: PopupProps) => {
    const dispatch = useAppDispatch()
    const onPrimaryButtonClick = () => {
        primaryButtonAction && primaryButtonAction()
    }
    const onSecondaryButtonClick = () => {
        secondaryButtonAction && secondaryButtonAction()
        onClose()
    }
    const onClose = () => {
        onClosePopUp && onClosePopUp()
    }

    const getFooter = () => (
        <div className=" absolute bottom-0 left-0 text-center py-1 bg-blue_2 w-full">
            <p className=" text-secondary_color font-semibold">Powered by Dyspach</p>
        </div>
    )
    return (
        <PopupWrapper
            isOpen={isOpen}
            onClose={onClosePopUp}
        >
            {icon ? (
                type === "error" ? (
                    <IconWrapper type={type}>
                        <EyeOff className="w-4 h-4 text-primary_error" />
                    </IconWrapper>
                ) : type === "success" ? (
                    <IconWrapper type={type}>
                        <CheckCircle className="w-4 h-4 text-success_primary" />
                    </IconWrapper>
                ) : (
                    <IconWrapper type={type}>
                        <AlertCircle className="w-4 h-4 text-primary_warning" />
                    </IconWrapper>
                )
            ) : null}
            <Title
                title={title}
                size="md"
            />
            <SubTitle
                description={message}
                size="sm"
            />
            {content}
            <div className="w-full flex items-center gap-2 mt-6 justify-center">
                {footer ? (
                    getFooter()
                ) : null} 
                {secondaryButtonAction ? (
                    <Button
                        buttonType={2}
                        label={secondaryButtonLabel || "Cancel"}
                        size="xl"
                        onClick={onSecondaryButtonClick}
                    />
                ) : null}
                {primaryButtonLabel ? (
                    <Button
                        showSpinner={showPrimaryButtonLoader}
                        buttonType={type === "error" ? 4 : 1}
                        label={primaryButtonLabel}
                        size="xl"
                        onClick={onPrimaryButtonClick}
                    />
                ) : null}
            </div>
        </PopupWrapper>
    )
}

export default Popup

const IconWrapper = ({ children, type }: { children: React.ReactNode; type: string }) => {
    return (
        <div
            className={classNames(
                type === "error"
                    ? "bg-error_50"
                    : type === "success"
                    ? "bg-green_3"
                    : type === "warning"
                    ? "bg-warning_background"
                    : "bg-background_primary",
                "w-10 h-10 rounded-md grid place-items-center mb-3"
            )}
        >
            {children}
        </div>
    )
}
