import { Link } from "react-router-dom"

const PoweredBy = () => {
  return (
      <p className=" mt-4 w-full text-center text-sm font-medium">
          Powered by{" "}
          <Link
              className=" underline font-bold text-dyspach_blue"
              target="_blank"
              to="https://www.dyspach.com"
          >
              Dyspach
          </Link>
      </p>
  )
}

export default PoweredBy