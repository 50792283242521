interface TitleProps {
  title: string;
  size?: "sm" | "md" | "lg";
}

const Title = ({ title, size = "lg" }: TitleProps) => {
  return (
    <p
      className={`font-semibold text-text_primary_color ${size === "sm" ? "text-lg" : "text-2xl"
        }`}
    >
      {title}
    </p>
  );
};

export default Title;
