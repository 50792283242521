import { classNames } from "../../utils/classNames";
import ButtonLoader from "./ButtonLoader";

interface ButtonProps {
  label?: string | React.ReactNode;
  size?: "sm" | "md" | "lg" | "xl";
  iconRTL?: boolean;
  icon?: React.ReactNode;
  type?: "button" | "submit" | "reset";
  buttonType?: number;
  disabled?: boolean;
  onClick?: () => void;
  showSpinner?: boolean
    className?: string
}

const Button = ({
  label,
  icon,
  iconRTL = false,
  type = "button",
  size,
  buttonType = 1,
  onClick,
  disabled = false,
  showSpinner = false,
  className
}: ButtonProps) => {

  return (
      <button
          type={type}
          className={classNames(
              buttonType === 2
                  ? "bg-transparent text-text_primary_color text-base border border-border_primary py-3 px-4"
                  : buttonType === 3
                  ? "bg-white text-button_secondary text-base border border-button_border_secondary rounded-sm py-2 px-4"
                  : buttonType === 4
                  ? "bg-primary_error text-white text-base border border-primary_error py-2 px-4"
                  : buttonType === 5
                  ? "bg-transparent text-primary_error text-base  py-2 text-sm font-medium rounded-sm flex items-center justify-center gap-2"
                  : buttonType === 6
                  ? "bg-transparent text-button_secondary border-none text-sm font-medium text-base py-2"
                  : buttonType === 7
                  ? "bg-background_primary text-foreground_primary border-none text-base py-2 px-4 opacity-100"
                  : buttonType === 8
                  ? "bg-transparent text-error_700 text-sm py-2 px-4 font-semibold rounded-sm flex items-center justify-center gap-2"
                  : "bg-primary_color text-white text-base font-medium py-3 px-4",
              size === "xl" ? "w-full" : size === "md" ? "w-36" : "",
              disabled ? "opacity-50 cursor-not-allowed" : "",
              className ? className : "",
              `font-medium flex items-center justify-center gap-2 rounded-lg relative overflow-hidden !text-sm sm:!text-base ${showSpinner && "pointer-events-none"}`
          )}
          onClick={onClick}
          disabled={disabled}
      >
          {/* {!showSpinner && getButtonContent()}
      {showSpinner && <ButtonLoader />} */}
          {icon && !iconRTL && icon}
          {showSpinner && (
              <div className="absolute inset-0 bg-black/50 grid place-content-center">
                  <ButtonLoader buttonType={buttonType} />
              </div>
          )}
          {label && label}
          {icon && iconRTL && icon}
      </button>
  )
};

export default Button;
