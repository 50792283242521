import { EnumProperties } from "../models/EnumProperties";

export enum DimensionalUnits {
    inch = 0,
    cm = 1,
}

export const DimensionalUnitsProperties: EnumProperties[] = [
  
    {
        displayName: "Centimeter (cm)",
        key: "cm",
        value: DimensionalUnits.cm
    },
    {
        displayName: "Inch (inch)",
        key: "inch",
        value: DimensionalUnits.inch
    }
]

export const DimensionalUnitsOptions = () => {
    let options: any[] = [];
    DimensionalUnitsProperties.forEach((item: EnumProperties) => {
        options.push({ label: item.displayName, value: item.value });
    });
    return options;
}

export const DimensionalUnitsByDisplayName = (displayName: string) => {
    let status = DimensionalUnitsProperties.find((item: EnumProperties) => item.displayName === displayName);
    return status ? status.value : '';
}

export const DimensionalUnitsByValue = (value: DimensionalUnits) => {
    let unit = DimensionalUnitsProperties.find((item: EnumProperties) => item.value === value);
    return unit ? unit.displayName : '';
}

export const DimensionalUnitsKeyByValue = (value: DimensionalUnits) => {
    let unit = DimensionalUnitsProperties.find((item: EnumProperties) => item.value === value);
    return unit ? unit.key : 'cm';
}
