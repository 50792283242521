import { EnumProperties } from "../models/EnumProperties";

export enum PhoneNumberDirectory {
    AU = "61",
    US = "1",
    UK = "44",
    NZ = "64",
    CA = "1",
    IN = "91",
    IE = "353",
    DE = "49",
    FR = "33",
    IT = "39",
    NL = "31",

}

export const PhoneNumberDirectoryProperties : EnumProperties[] = [
    {
        displayName: "+61",
        key: "AUS",
        value: PhoneNumberDirectory.AU
    },
    {
        displayName: "+1",
        key: "USA",
        value: PhoneNumberDirectory.US
    },
    {
        displayName: "+44",
        key: "UK",
        value: PhoneNumberDirectory.UK
    },
    {
        displayName: "+64",
        key: "NZ",
        value: PhoneNumberDirectory.NZ
    },
    // {
    //     displayName: "+1",
    //     key: "CAN",
    //     value: PhoneNumberDirectory.CAN
    // },
    {
        displayName: "+91",
        key: "IND",
        value: PhoneNumberDirectory.IN
    },
    {
        displayName: "+353",
        key: "IRE",
        value: PhoneNumberDirectory.IE
    },
    {
        displayName: "+49",
        key: "GER",
        value: PhoneNumberDirectory.DE
    },
    {
        displayName: "+33",
        key: "FRA",
        value: PhoneNumberDirectory.FR
    },
    {
        displayName: "+39",
        key: "ITA",
        value: PhoneNumberDirectory.IT
    },
    {
        displayName: "+31",
        key: "NET",
        value: PhoneNumberDirectory.NL
    },
]

export const PhoneNumberDirectoryOptions = () => {
    let options: any[] = [];
    PhoneNumberDirectoryProperties.forEach((item: EnumProperties) => {
        options.push({ label: item.displayName, value: item.value });
    });
    return options;
}

export const PhoneNumberDirectoryByDisplayName = (displayName: string) => {
    let status = PhoneNumberDirectoryProperties.find((item: EnumProperties) => item.displayName === displayName);
    return status ? status.value : '';
}

export const PhoneNumberDirectoryByValue = (value: PhoneNumberDirectory) => {
    let unit = PhoneNumberDirectoryProperties.find((item: EnumProperties) => item.value === value);
    return unit ? unit.displayName : '';
}

export function PhoneNumOptionByCountry(countryCode?: string) {
    const optionsArr = PhoneNumberDirectoryOptions() 

    if (!countryCode) return optionsArr[0]

    const phoneNumberValue = PhoneNumberDirectory[countryCode as keyof typeof PhoneNumberDirectory]
    const value =  optionsArr.find((property) => property.value === phoneNumberValue)
    if (!value) {
        return optionsArr[0]
    }
    return value;
}