import React, { useEffect, useRef, useState } from "react"
import ReCAPTCHA from "react-google-recaptcha";

import { TestRateCardDTO, defaultTestRateCardDTO } from "../../../models/TestRateCardDTO"
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form"
import RadioButton from "../../input/RadioButtons"
import { RateItem, defaultRateItem } from "../../../models/RateItem"
import DynamicDropdown from "../../input/DynamicDropdown"
import { MasterPostCode } from "../../../models/MasterPostCode"
import {
    useGetPostCodesQuery,
    useGetQuoteMutation,
    useUploadBulkQuoteRequestMutation
} from "../../../api/WidgetQuoteAPI"
import GenericRadioButton from "../../input/GenericRadioButton"
import { BuildingTypeOptions } from "../../../enum/ShipmentType"
import { BuildingType, BuildingTypeByValue } from "../../../enum/BuildingType"
import Button from "../../button/Button"
import ItemForm from "./ItemForm"
import Footer from "../../common/Footer"
import Input from "../../input/CustomInput"
import { validateEmail } from "../../../utils/CommonUtil"
import Popup from "../../popup/Popup"
import Mail03 from "@untitled-ui/icons-react/build/esm/Mail03"
import Phone01 from "@untitled-ui/icons-react/build/esm/Phone01"
import { useParams } from "react-router-dom"
import FileUpload from "../../fileUpload/FileUpload"
import { BulkQuoteRequest, defaultBulkQuoteRequest } from "../../../models/BulkQuoteRequest"
import { FileUploadParams } from "../../../models/QueryParams"
import SuccessPopup from "../../popup/SuccessPopup"
import PhoneNumberInput from "../../input/PhoneNumberInput"
import { parsePhoneNumber } from "libphonenumber-js"
import PoweredBy from "../../PoweredBy"
import { useDispatch } from "react-redux"
import { openErrorPopup, setOnErrorPopupClose } from "../../../store/features/ErrorPopupSlice"
import AlertBox from "../../alertBox/AlertBox";
import { Widget } from "../../../models/Widget";
import { Carrier } from "../../../models/Carrier";
import { QuoteWrapperProps } from "../QuoteWrapper";

const siteKey = '6LcS5qwpAAAAAH_kOO0kZHUdHe-AhMckqVKPa_Ky';


const GetBulkQuoteForm: React.FC<any> = (props: QuoteWrapperProps) => {
    const dispatch = useDispatch()
    const {
        control,
        register,
        unregister,
        handleSubmit,
        getValues,
        setValue,
        reset: resetForm,
        formState: { errors }
    } = useForm<BulkQuoteRequest>({ defaultValues: defaultBulkQuoteRequest })
    const [uploadBulkQuoteRequest, { isLoading, isError, status, isSuccess, reset: resetUploadBulkQuoteMutation }] = useUploadBulkQuoteRequestMutation()
    const [file, setFile] = useState<File | null>(null)
    const [formData, setFormData] = useState<BulkQuoteRequest>(defaultBulkQuoteRequest)
    const recaptchaRef = useRef<ReCAPTCHA>(null);

    const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useState(false)

    const [email, setEmail] = useState<string>("")
    const [emailError, setEmailError] = useState(false)
    const [showEmailInput, setShowEmailInput] = useState<boolean>(false)
    const { widgetId } = useParams<{ widgetId: string }>()
    const [validFile, setValidFile] = useState<boolean>(true)

    const [invalidEmail, setInvalidEmail] = React.useState<boolean>(false)
    const checkValidEmail = (email: string) => {
        let isValid = validateEmail(email)
        setInvalidEmail(!isValid)
        return isValid
    }

    const [invalidMobile, setInvalidMobile] = React.useState<boolean>(false)

    useEffect(() => {
        if (isError) {
            dispatch(openErrorPopup({ isOpen: true }))
            dispatch(setOnErrorPopupClose({
                onClose: () => {
                    resetForm()
                    dispatch(openErrorPopup({ isOpen: false }))
                }
            }))
            resetUploadBulkQuoteMutation()
        }
    }, [isError])


    useEffect(() => {
        if (isSuccess) {
            setIsSuccessPopupOpen(true)
            resetForm(defaultBulkQuoteRequest)
            setFile(null)
        }
    }, [isSuccess])

    const checkValidMobile = (mobile: string) => {
        if (mobile.length < 5) {
            setInvalidMobile(true)
            return false
        }
        let isValid
        try {
            isValid = parsePhoneNumber(mobile).isValid()
        } catch (error) {
            console.log(error)
            setInvalidMobile(true)
            return false
        }
        setInvalidMobile(!isValid)
        return isValid
    }

    const handleSave: SubmitHandler<BulkQuoteRequest> = async (data) => {
        const isFileValid = !!file
        const token = await recaptchaRef.current?.executeAsync();
        recaptchaRef.current?.reset();
        setValidFile(isFileValid)
        if (!isFileValid) {
            return
        }
        let formdata = new FormData()
        formdata.append("token", token ?? "")
        formdata.append("excelFile", file)
        formdata.append("formData", JSON.stringify(data))
        let uploadData: FileUploadParams = {
            data: formdata,
            id: widgetId ?? ""
        }
        try {
            uploadBulkQuoteRequest(uploadData)
        } catch (error) {
            console.log(error)
        }
    }

    const getFooterButton = () => {
        let buttonList: any[] = []
        buttonList.push(
            <Button
                type={"submit"}
                buttonType={1}
                label="Request Assessment"
            />
        )
        return buttonList
    }

    const getSendQuoteContent = () => {
        return (
            <div className="flex flex-col mt-8 mb-4">
                <Input
                    label="Business Email"
                    value={email}
                    handleChange={handleResetEmailChange}
                    placeholder="Enter your Business Email"
                    type="email"
                    error={emailError ? "Enter valid email" : ""}
                />
            </div>
        )
    }

    const handleBusinessNameChange = (value: string) => {
        setValue("businessName", value)
    }

    const handleFullNameChange = (value: string) => {
        setValue("fullName", value)
    }

    const handleMobileNumberChange = (value: string) => {
        setValue("mobileNumber", value)
    }

    const handleBusinessEmailChange = (value: string) => {
        setValue("businessEmail", value)
    }

    const handleNoteChange = (value: string) => {
        setValue("note", value)
    }

    const handleResetEmailChange = (value: string) => {
        setEmail(value)
        setEmailError(false)
    }

    const handleOnFileChange = (file: File | null) => {
        setValidFile(true)
        setFile(file)
    }

    const downloadBulkQuoteTemplate = () => {
        window.open("https://dyspach-spaces.syd1.cdn.digitaloceanspaces.com/BulkQuote-Template.xlsx", "_blank")
    }

    return (
        <form onSubmit={handleSubmit(handleSave)}>
            <ReCAPTCHA
                sitekey={siteKey}
                size="invisible"
                ref={recaptchaRef}
            />
            <div className="grid grid-cols-2 gap-3 md:gap-4">
                <Input
                    handleChange={handleBusinessNameChange}
                    label="Business Name"
                    placeholder="Type company name"
                    {...register("businessName", { required: true })}
                    value={getValues().businessName}
                    error={errors.businessName ? "Business Name is mandatory" : ""}
                />
                <Input
                    handleChange={handleFullNameChange}
                    label="Full Name"
                    placeholder="Type full name"
                    {...register("fullName", { required: true })}
                    value={getValues().fullName}
                    error={errors.fullName ? "Name is mandatory" : ""}
                />
            </div>
            <div className="grid min-[500px]:grid-cols-2 gap-3 md:gap-4 mt-4">
                <PhoneNumberInput
                    carrierCountryCode={props.data.carrier.countryCode}
                    value={getValues().mobileNumber}
                    label="Mobile Number"
                    placeholder="Type mobile number"
                    handleChange={handleMobileNumberChange}
                    {...register("mobileNumber", { required: true, validate: checkValidMobile })}
                    error={errors.mobileNumber ? (invalidMobile ? "Invalid number" : "Mobile number is mandatory") : ""}
                />
                <Input
                    label="Email Address"
                    placeholder="Type your email"
                    handleChange={handleBusinessEmailChange}
                    value={getValues().businessEmail}
                    {...register("businessEmail", { required: true, validate: checkValidEmail })}
                    error={errors.businessEmail ? (invalidEmail ? "Invalid Email address" : "Field is mandatory") : ""}
                />
            </div>
            <div className="grid gap-4 pt-4">
                <Input
                    rows={2}
                    placeholder="Type here..."
                    multiline={true}
                    handleChange={handleNoteChange}
                    label="Any specific requirements we need to address?"
                    {...register("note")}
                    value={getValues().note}
                />
            </div>

            <p className=" text-sm font-normal mt-4 text-text_primary_color">
                Origin & destination postcodes, dimensions, weight, building type and quantity for each consignment are
                required to estimate quotes (incl. surcharges).
            </p>
            <div className="grid gap-6 pt-4">
                <FileUpload
                    onChange={handleOnFileChange}
                    file={file}
                    label="Upload File"
                    error={validFile ? "" : "Field is mandatory"}
                />
            </div>

            { props.data.shipperId && <div className="grid grid-cols-2 gap-4 mt-4">
                <Button
                    buttonType={2}
                    onClick={downloadBulkQuoteTemplate}
                    type="button"
                    label="Download Template"
                />
                <Button
                    showSpinner={isLoading}
                    type="submit"
                    buttonType={1}
                    label="Request Assessment"
                />
            </div>
            }
            {
                !props.data.shipperId && <AlertBox type={"error"} title="Warning" message={'Account is not associated with this widget. Please link account to generate quotes'} />
            }

            <PoweredBy />

            <SuccessPopup
                isOpen={isSuccessPopupOpen}
                onClose={() => setIsSuccessPopupOpen(false)}
                title="Details submitted."
                desc="We've sent you a confirmation and will get back in the next 1-2 business days."
                buttonList={[
                    <Button
                        size="xl"
                        buttonType={1}
                        label="Get new quote"
                        onClick={() => setIsSuccessPopupOpen(false)}
                    />
                ]}
            />
        </form>
    )
}

export default GetBulkQuoteForm
